import { useState, useEffect } from 'react';
import { initApollo } from 'lib/apollo/initApollo';

const useApolloClient = (initState = {}, options = {}) => {
  const [client, setClient] = useState(null);

  const hasClient = client !== null;

  useEffect(() => {
    const apolloClient = initApollo(initState, options);
    setClient(apolloClient);
  }, [hasClient]);

  return client;
};

export { useApolloClient };

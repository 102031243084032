import { useState, useRef, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';

const useUndoableMutation = (MUTATION, {
  delay = 3000,
  ...options
}) => {
  const [delaying, setDelaying] = useState(false);
  const mutationRef = useRef();
  const timeoutRef = useRef();
  const [mutation, { loading, ...rest }] = useMutation(
    MUTATION,
    options
  );

  useEffect(() => {
    mutationRef.current = mutation;
  }, [mutation]);

  const delayedMutation = (mutationParams) => {
    setDelaying(true);
    timeoutRef.current = setTimeout(() => {
      mutationRef.current(mutationParams);
      setDelaying(false);
    }, delay);
  };

  const undoMutation = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      setDelaying(false);
    }
  };

  return [
    delayedMutation,
    undoMutation,
    {
      loading: delaying || loading,
      ...rest
    }
  ];
};

export { useUndoableMutation };
import { useSplitClient } from 'splitio-react';
import { useAuth } from 'hooks/use-auth';
import get from 'lodash.get';

const useSplitTrack = () => {
  const { organisationId } = useAuth();
  const client = useSplitClient();

  if (!get(client, 'track')) {
    return () => {};
  }

  return (event, value = null, properties = {}) => {
    client.track('user', event, value, { organisationId, ...properties });
  };
};

export { useSplitTrack };

import get from 'lodash.get';
import { useAlgoliaSearch } from 'react-algolia';
import { getIndexNameFromType } from 'lib/algolia';

const useGroupQuery = ({
  query,
  page = 1,
  pageSize = 10,
  groupType,
  key = 0
}) => {
  const { searchResults, ...restProps } = useAlgoliaSearch({
    indexName: getIndexNameFromType('group'),
    query,
    page: page - 1,
    hitsPerPage: pageSize,
    filters: groupType ? `active:true AND group_type:${groupType}` : 'active:true',
    key
  });

  const groups = (get(searchResults, 'hits') || []).map(group => {
    const {
      objectID,
      study_plan_id,
      name,
      course_title,
      description,
      color_scheme,
      start_date,
      complete_date,
      study_plan_active
    } = group;

    return {
      id: objectID,
      name,
      courseTitle: course_title,
      description,
      colorScheme: color_scheme,
      studyPlanId: study_plan_id,
      startDate: start_date,
      completeDate: complete_date,
      active: study_plan_active || false
    };
  });
  return {
    data: groups,
    pagination: {
      total: get(searchResults, 'nbHits') || 0,
      page,
      pageSize
    },
    ...restProps
  };
};

export { useGroupQuery };
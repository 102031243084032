import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const NUMBER_OF_ORG_LEARNING_PATH_USERS = gql`
  query Teams_NumberOfOrganisationLearningPathUsers($learningPathId: ID!) {
    numberOfOrganisationLearningPathUsers(learningPathId: $learningPathId)
  }
`;

const useLearningPathUsersCountQuery = ({ learningPathId }) => {
  const { data, loading, error } = useQuery(NUMBER_OF_ORG_LEARNING_PATH_USERS, {
    variables: {
      learningPathId
    }
  });

  return {
    data: data?.numberOfOrganisationLearningPathUsers,
    loading,
    error
  };
};

export { useLearningPathUsersCountQuery, NUMBER_OF_ORG_LEARNING_PATH_USERS };

import get from 'lodash.get';

import { useProfile } from 'hooks/use-profile';

const EXPIRED_LICENSE_NO_FEATURES_CUT_OFF = -30;

const useActiveSubscription = () => {
  const { organisation } = useProfile();

  const daysRemaining = get(organisation, 'subscription.daysRemaining');
  const activeSubscription = !(daysRemaining < 0 && daysRemaining > EXPIRED_LICENSE_NO_FEATURES_CUT_OFF);

  return {
    activeSubscription
  }
};

export { useActiveSubscription };

import { useReducer, useEffect } from 'react';
import { groupBy, get, chunk } from 'lodash';
import Bluebird from 'bluebird';
import { useAlgoliaIndex } from 'react-algolia';
import { getIndexNameFromType } from 'lib/algolia';
import { usePrevious } from 'hooks/use-previous';
import { ROLES } from 'constants';

const CURRENT_TIME = new Date().valueOf();

const INITIAL_STATE = {
  browseResult: [],
  loading: true,
  error: undefined,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'fetching':
      return {
        ...state,
        loading: true,
      };

    case 'success':
      return {
        ...INITIAL_STATE,
        browseResult: action.payload.browseResult,
        loading: false,
      };

    case 'error':
      return {
        ...INITIAL_STATE,
        error: action.payload.error,
        loading: false,
      };

    default:
      return state;
  }
};

const getTeamsUserFilter = ({ teams }) => {
  let teamsUserFilter = 'active:true AND organisation_license_consumed:true';

  if (teams.length > 0) {
    teamsUserFilter += ` AND ${teams.map(({ id }) => `team:${id}`).join(' OR ')}`;
  }
  return teamsUserFilter;
};

const getTeamsInvitationFilter = ({ teams }) => {
  let teamsInvitationFilter = `accepted_at = 0 AND valid_until > ${CURRENT_TIME}`;

  if (teams.length > 0) {
    teamsInvitationFilter += ` AND ${teams.map(({ id }) => `team:${id}`).join(' OR ')}`;
  }

  return teamsInvitationFilter;
};

const populateTeamUsersAndInvitationCount = ({
  teams,
  teamsUsers,
  teamsInvitations,
}) => {
  const teamsUsersByTeam = groupBy(teamsUsers, 'team');
  const teamsInvitaionsByTeam = groupBy(teamsInvitations, 'team');

  return teams.map(({ id, name }) => ({
    id,
    name,
    numberOfTeamsUsers: get(teamsUsersByTeam, id, []).length,
    numberOfTeamsCoordinators: get(teamsUsersByTeam, id, []).filter(({ organisation_team_coordinator }) => !!organisation_team_coordinator).length,
    numberOfTeamsInvitations: get(teamsInvitaionsByTeam, id, []).length,
    numberOfTeamsCoordinatorInvitations: get(teamsInvitaionsByTeam, id, []).filter(({ invite_role }) => invite_role === ROLES.TEAM_COORDINATOR).length
  }));
};

const useCombinedTeamsUserInvitationQuery = ({ teams, key = 0 } = {}) => {
  const usersIndex = useAlgoliaIndex({
    indexName: getIndexNameFromType('user'),
  });
  const invitationsIndex = useAlgoliaIndex({
    indexName: getIndexNameFromType('invitation'),
  });
  const [{ loading, browseResult, error }, dispatch] = useReducer(
    reducer,
    INITIAL_STATE
  );

  const browseChunkedTeams = async ({ teams }) => {
    const teamsUsersFilter = getTeamsUserFilter({ teams });
    const teamsInvitationFilter = getTeamsInvitationFilter({ teams });
    const result = await Promise.all([
      usersIndex.browseAll('', {
        attributesToRetrieve: ['team', 'organisation_team_coordinator'],
        filters: teamsUsersFilter,
      }),
      invitationsIndex.browseAll('', {
        attributesToRetrieve: ['team', 'invite_role'],
        filters: teamsInvitationFilter,
      })
    ]);

    return result;
  }

  const browseAllTeams = async ({ teams }) => {
    let teamsData = { teamsUsers: [], teamsInvitations: [] };
    const chunkSize = 10;
    const chunkedTeams = chunk(teams, chunkSize);

    try {
      const requests = chunkedTeams.map(chunk => async () => {
        const result = await browseChunkedTeams({ teams: chunk });
        teamsData =  {
          teamsUsers: [
            ...teamsData['teamsUsers'],
            ...result[0]
          ],
          teamsInvitations: [
            ...teamsData['teamsInvitations'],
            ...result[1]
          ]
        };
      });

      await Bluebird.mapSeries(requests, request => request());

      dispatch({
        type: 'success',
        payload: { browseResult: teamsData },
      });
    } catch (err) {
      dispatch({
        type: 'error',
        payload: { error: err },
      });
    }
  };

  useEffect(() => {
    const browse = async () => {
      dispatch({ type: 'fetching' });
      await browseAllTeams({ teams });
    };

    if (!usersIndex || !invitationsIndex) {
      return;
    }

    browse();
  }, [JSON.stringify(teams), key, !!usersIndex, !!invitationsIndex]);

  const data = populateTeamUsersAndInvitationCount({
    teams,
    teamsUsers: get(browseResult, 'teamsUsers', []),
    teamsInvitations: get(browseResult, 'teamsInvitations', []),
  });

  const prevData = usePrevious(data);

  return {
    data: loading ? prevData : data,
    loading,
    error,
  };
};

export { useCombinedTeamsUserInvitationQuery };

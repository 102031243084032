import { addDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { getConfig } from 'config/get-config';

const { SHOW_DRIFT_CHAT } = getConfig();

export const useDriftChat = ({
  profile,
  organisation
}) => {
  const [showingChat, setShowingChat] = useState(false);
  const createdAtValue = get(organisation, 'createdAt') || addDays(new Date(), 25);
  const createdAt = new Date(createdAtValue).toISOString();
  const userId = get(profile, 'id');
  const email = get(profile, 'email');
  const name = get(profile, 'name');
  const isAdmin = get(profile, 'rawRoles.organisationAdmin', false);
  const endDateValue = get(organisation, 'subscription.endDate');
  const endDate = endDateValue ? new Date(endDateValue).toISOString() : null;
  const startDateValue = get(organisation, 'subscription.startDate');
  const startDate = startDateValue ? new Date(startDateValue).toISOString() : null;
  const identity = {
    name,
    email,
    organization_account_type: get(organisation, 'accountType'),
    organization_charge_model: get(organisation, 'chargeModel'),
    organization_consumed_licenses: get(organisation, 'consumedLicenses'),
    organization_created_at: createdAt,
    organization_remaining_licenses: get(organisation, 'remainingLicenses'),
    organization_days_remaining: get(organisation, 'subscription.daysRemaining'),
    organization_end_date: endDate,
    organization_expired: get(organisation, 'subscription.expired'),
    organization_start_date: startDate,
    organization_admin: isAdmin,
    organization_license_count: get(organisation, 'subscription.userLicenseCount')
  };

  useEffect(() => {
    if (process.browser && window.drift && userId && isAdmin && SHOW_DRIFT_CHAT && !showingChat) {
      // only show the interaction once
      setShowingChat(true);
      window.drift.on('ready', () => {
        window.drift.identify(userId, identity);
      });
    }
  }, [process.browser, userId, isAdmin, identity, showingChat]);
};

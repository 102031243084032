import { useEffect } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const TRACK_LOGGED_IN_EVENT = gql`
  mutation trackEvent($event: EventInput) {
    trackEvent(event: $event)
  }
`;

const useTrackLoggedInAdmin = ({
  organisation,
  profile
}) => {
  const [sendTrackEvent] = useMutation(TRACK_LOGGED_IN_EVENT);
  const organisationId = organisation?.id;
  const userId = profile?.id;

  useEffect(() => {
    const variables = {
      event: {
        eventType: "Org admin - dashboard visited",
        properties: JSON.stringify({
          page: window.location.pathname,
          organisationId
        })
      }
    };

    if(organisationId && userId) {
      sendTrackEvent({ variables });
    }
  }, [organisationId, userId]);
};

export { useTrackLoggedInAdmin };

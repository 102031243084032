import { getConfig } from 'config/get-config';
import React from 'react';

import { authService } from 'services/auth';
import { isBrowser } from 'utils/typedGlobals';

const { GOOGLE_ANALYTICS_TRACKING_ID, GOOGLE_TAG_MANAGER_CONTAINER_ID } = getConfig();

// for page views
// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageView = async url => {
  if (GOOGLE_ANALYTICS_TRACKING_ID && isBrowser) {
    const { userId, organisationId } = await getUser();

    window.gtag('config', GOOGLE_ANALYTICS_TRACKING_ID, {
      page_location: url,
      user_id: userId,
      organisation_id: organisationId
    });
  }
};

export const getUser = async () => {
  const userId = await authService.getUserIdFromToken();
  const organisationId = await authService.getOrganisationIdFromToken();

  return {
    userId,
    organisationId
  };
}

// for generic events
// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  if (GOOGLE_ANALYTICS_TRACKING_ID && isBrowser) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  }
};

export const GASnippet = () => {
  return GOOGLE_ANALYTICS_TRACKING_ID ? (
    <React.Fragment>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_TRACKING_ID}`}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${GOOGLE_ANALYTICS_TRACKING_ID}');
            `,
        }}
      />
    </React.Fragment>
  ) : null;
};

export const GtmHeadSnippet = () => {
  return GOOGLE_TAG_MANAGER_CONTAINER_ID ? (
    <script
      dangerouslySetInnerHTML={{
        __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_CONTAINER_ID}')
        `
      }}
    />
  ) : null;
};

export const GtmBodySnippet = () => {
  return GOOGLE_TAG_MANAGER_CONTAINER_ID ? (
    <noscript
      dangerouslySetInnerHTML={{
        __html: `
          <iframe
            src='https://www.googletagmanager.com/ns.html?id=${GOOGLE_TAG_MANAGER_CONTAINER_ID}'
            height="0"
            width="0"
            style="display:none;visibility:hidden"
          />
        `
      }}
    />
  ) : null;
};

import { useState, useEffect } from 'react';
import { authService } from 'services/auth';

const useAuthorizer = () => {
  const [authorized, setAuthorized] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [authenticating, setAuthenticating] = useState(true);
  const [organisationId, setOrganisationId] = useState();
  const [userId, setUserId] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const authenticate = async () => {
      try {
        const {
          isAuthenticated,
          isAuthorized,
          error
        } = await authService.authorize({
          allowRoles: ['ORGANISATION_ADMIN']
        });

        if (error) {
          setError(error);
        }

        setAuthorized(isAuthorized);
        setAuthenticated(isAuthenticated);

        if (isAuthorized) {
          const [userIdFromToken, organisationIdFromToken] = await Promise.all([
            authService.getUserIdFromToken(),
            authService.getOrganisationIdFromToken()
          ]);

          setUserId(userIdFromToken);
          setOrganisationId(organisationIdFromToken);
        }
      } catch (err) {
        setAuthorized(false);
        setAuthenticated(false);
        setUserId(undefined);
        setOrganisationId(undefined);
        setError(err);
      }

      setAuthenticating(false);
    };

    authenticate();
  }, []);

  return {
    authorized,
    authenticated,
    authenticating,
    userId,
    organisationId,
    error
  };
};

export { useAuthorizer };
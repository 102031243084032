import { get, keyBy } from 'lodash';
import { isAfter } from 'date-fns';
import { useRef, useState, useEffect } from 'react';
import { usePrevious } from 'hooks/use-previous';
import { useAlgoliaIndex } from 'react-algolia';
import { getIndexNameFromType } from 'lib/algolia';

const NOW = new Date();

const makeGetUsersWithGroupsInfo = ({
  userIndex,
  groupIndex,
  setLoading,
  setError,
  setData
}) => async ({
  query = '',
  page = 1,
  pageSize = 10
}) => {
  try {
    const searchResults = await userIndex.search({
      query,
      page: page - 1,
      hitsPerPage: pageSize,
      filters: 'active:true'
    });

    const users = get(searchResults, 'hits') || [];

    const groupIds = users.reduce((total, current) => {
      return [...total, ...(get(current, 'group') || [])];
    }, []);

    const uniqueGroupIds = groupIds.filter((id, index) => groupIds.indexOf(id) === index);

    const { results } = await groupIndex.getObjects(uniqueGroupIds);

    const groups = results.filter(Boolean);
    const groupMap = keyBy(groups, 'group_id') || {};

    const usersWithGroupsInfo = users.map(user => {
      if (!user.group || user.group.length === 0) {
        return { ...user, groups: [] };
      }

      const userGroups = user.group.map(groupId => {
        const group = groupMap[groupId]

        if (!group || !group.active) {
          return;
        }

        const completeDate = get(group, 'complete_date');
        return { ...group, completed: completeDate && isAfter(NOW, completeDate)};
      });

      return {
        ...user,
        groups: userGroups.filter(Boolean)
      }
    });
    setData(usersWithGroupsInfo);
  } catch (err) {
    setError(err);
  }

  setLoading(false);
}

const useOrganisationUsersWithGroups = ({
  query,
  page = 1,
  pageSize = 10
}) => {
  const handlerRef = useRef();
  const prevQuery = usePrevious(query);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);

  const userIndex = useAlgoliaIndex({ indexName: getIndexNameFromType('user') });
  const groupIndex = useAlgoliaIndex({ indexName: getIndexNameFromType('group') });

  const hasIndexInit = userIndex && groupIndex;

  useEffect(() => {
    if (!hasIndexInit) {
      return;
    }

    const getUsersWithGroupsInfo = makeGetUsersWithGroupsInfo({
      userIndex,
      groupIndex,
      setLoading,
      setError,
      setData
    });

    setLoading(true);

    if (prevQuery !== query) {
      handlerRef.current = setTimeout(() => getUsersWithGroupsInfo({
        query,
        page,
        pageSize
      }), 800);
    } else {
      getUsersWithGroupsInfo({
        query,
        page,
        pageSize
      })
    }

    return () => {
      if (handlerRef.current) {
        clearTimeout(handlerRef.current);
      }
    }
  }, [query, page, pageSize, hasIndexInit]);

  return {
    data,
    loading,
    error
  };
};

export { useOrganisationUsersWithGroups };
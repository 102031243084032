import { Query as ApolloQuery } from 'react-apollo';

const Query = ({ context, ...props }) => (
  <ApolloQuery
    {...props}
    context={context}
  />
);

export { Query };

import { get, remove, flow } from 'lodash';

const assignUserRoles = userIdentity => {
  const determineOrganisationAdmin = userIdentity => organisationRoles =>
    get(userIdentity, 'roles.organisationAdmin')
      ? organisationRoles.concat(['ORGANISATION_ADMIN'])
      : organisationRoles;

  const determineTeamCoordinator = userIdentity => organisationRoles =>
    (get(userIdentity, 'roles.organisationAdmin') && get(userIdentity, 'managedTeamId'))
      ? remove(organisationRoles, 'ORGANISATION_ADMIN').concat(['TEAM_COORDINATOR'])
      : organisationRoles;

  const userRoles = flow([
    determineOrganisationAdmin(userIdentity),
    determineTeamCoordinator(userIdentity)
  ])([]);

  return userRoles;
};

export { assignUserRoles };

import gql from 'graphql-tag';

const MODIFY_TEAMS_MUTATION = gql`
  mutation TEAMS_modifyTeams($input: ModifyTeamsInput!) {
    modifyTeams(input: $input) {
    taskId
    teams {
      id
      name
      deletedAt
      updatedAt
    }
  }
  }
`;

export { MODIFY_TEAMS_MUTATION };

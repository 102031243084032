import { flow, get } from 'lodash';
import { ROLES, FILTER_ROLES, FILTER_ROLES_DISPLAY_MAP } from 'constants';

const getRoleAndDescription = user => {
  if (user.organisation_team_coordinator && user.organisation_admin_only) {
    return {
      role: ROLES.TEAM_COORDINATOR,
      role_description: FILTER_ROLES_DISPLAY_MAP.TEAM_COORDINATOR_ONLY
    };
  }

  if (user.organisation_team_coordinator && !user.organisation_admin_only) {
    return {
      role: ROLES.TEAM_COORDINATOR,
      role_description: FILTER_ROLES_DISPLAY_MAP.TEAM_COORDINATOR_STUDENT
    };
  }

  if (user.organisation_admin && user.organisation_admin_only) {
    return {
      role: ROLES.ADMIN,
      role_description: FILTER_ROLES_DISPLAY_MAP.ADMIN_ONLY
    };
  }

  if (user.organisation_admin && !user.organisation_admin_only) {
    return {
      role: ROLES.ADMIN,
      role_description: FILTER_ROLES_DISPLAY_MAP.ADMIN_STUDENT
    };
  }

  return {
    role: ROLES.STUDENT,
    role_description: FILTER_ROLES_DISPLAY_MAP.STUDENT
  };
};

const transformUsersAccessRecords = users =>
  users.map(user => {
    const { role, role_description } = getRoleAndDescription(user);
    return {
      ...user,
      id: user.user_id,
      display: user.displayname || user.email,
      role,
      role_description,
      status: user.organisation_license_consumed ? 'Active' : 'Deactivated',
      last_activity: user.last_seen_timestamp,
      team: get(user, 'team') === 'no-team' ? undefined : get(user, 'team'),
      type: 'user',
      admin_only: user.organisation_admin_only || false
    };
  });

const getUserRoleFilter =
  ({ roles }) =>
  (filter = 'active:true') => {
    if (roles.includes('all')) {
      return filter;
    }

    filter += ` AND `;

    const filters = [];

    if (!roles.includes(FILTER_ROLES.ADMIN_ONLY)) {
      filters.push('organisation_admin:false OR organisation_admin_only:false');
    }

    if (!roles.includes(FILTER_ROLES.ADMIN_STUDENT)) {
      filters.push('organisation_admin:false OR organisation_admin_only:true');
    }

    if (!roles.includes(FILTER_ROLES.TEAM_COORDINATOR_ONLY)) {
      filters.push('organisation_team_coordinator:false OR organisation_admin_only:false');
    }

    if (!roles.includes(FILTER_ROLES.TEAM_COORDINATOR_STUDENT)) {
      filters.push('organisation_team_coordinator:false OR organisation_admin_only:true');
    }

    if (!roles.includes(FILTER_ROLES.STUDENT)) {
      filters.push('organisation_student:false');
    }
    return filter + filters.join(' AND ');
  };

const getUserStatusFilter =
  ({ status }) =>
  (filter = 'active:true') => {
    if (status.includes('active') && !status.includes('inactive')) {
      filter += ' AND organisation_license_consumed:true';
    }

    if (!status.includes('active') && status.includes('inactive')) {
      filter += ' AND organisation_license_consumed:false';
    }

    return filter;
  };

const getUserTeamsFilter =
  ({ teams }) =>
  (filter = 'active:true') => {
    if (teams && !teams.includes('all')) {
      filter += ` AND ${teams.map(id => `team:${id}`).join(' OR ')}`;
    }

    return filter;
  };

const getuserAssignmentsFilter = ({ roles, status, teams }) => {
  const hasUserStatus = status.includes('all') || status.includes('active') || status.includes('inactive');

  if (!hasUserStatus) {
    return 'empty:true';
  }

  return flow(
    getUserRoleFilter({ roles }),
    getUserStatusFilter({ status }),
    getUserTeamsFilter({ teams })
  )('active:true');
};

export { getuserAssignmentsFilter, transformUsersAccessRecords };

import { getConfig } from 'config/get-config';
import urlJoin from 'url-join';
import { makeAuthService } from '@a-cloud-guru/auth0-wrapper';

const {
  AUTH0_DOMAIN,
  AUTH0_CUSTOM_DOMAIN,
  AUTH0_API_AUDIENCE,
  AUTH0_CLIENT_ID,
  ACG_TEAMS_DOMAIN
} = getConfig();

const authService = makeAuthService({
  AUTH0_DOMAIN,
  AUTH0_CUSTOM_DOMAIN,
  AUTH0_API_AUDIENCE,
  AUTH0_CLIENT_ID,
  DEFAULT_REDIRECT_URI: urlJoin(ACG_TEAMS_DOMAIN, 'login', 'callback')
});

// add retry without cache
const extendedAuthService = {
  ...authService,
  authorize: (options) => {
    return authService.authorize(options)
      .then(({ isAuthenticated, isAuthorized, error }) => {
        if (isAuthenticated && isAuthorized && !error) {
          return {
            isAuthenticated,
            isAuthorized,
            error
          };
        }

        return authService.authorize({ ...options, skipCache: true });
      });
  }
};

export {
  extendedAuthService as authService
};

import { useIsPermissionAuthorised } from './use-is-permission-authorised';
import { useProfile } from './use-profile';
import { useAuth } from './use-auth';
import { FEATURES } from 'constants/permissions';

export const useIsClpAuthorised = () => {
  const { loading: loadingProfile } = useProfile();
  const { authenticating } = useAuth();
  const includeCustomLearningPath = useIsPermissionAuthorised(FEATURES.CUSTOM_LEARNING_PATHS);

  // If loading profile or authenticating then we cannot evaluate if the user has the correct package.
  return {
    loading: loadingProfile || authenticating,
    includeCustomLearningPath
  };
};
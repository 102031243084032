import get from 'lodash.get';
import { useAlgoliaSearch } from 'react-algolia';
import { getIndexNameFromType } from 'lib/algolia';

const useGroupUsersQuery = ({
  query,
  page = 1,
  pageSize = 10,
  groupId,
  sortOrder = 'descend'
}) => {
  const { searchResults, ...restProps } = useAlgoliaSearch({
    indexName: getIndexNameFromType('user', { field: 'last_seen_timestamp', order: sortOrder }),
    query,
    page: page - 1,
    hitsPerPage: pageSize,
    filters: `active:true AND group:${groupId}`
  });

  return {
    data: get(searchResults, 'hits') || [],
    pagination: {
      total: get(searchResults, 'nbHits') || 0,
      page,
      pageSize
    },
    ...restProps
  };
};

export { useGroupUsersQuery };

import { AuthContext } from './AuthContext';
import { useAuthorizer } from './use-authorizer';

const AuthProvider = ({
  children
}) => {
  const auth = useAuthorizer();

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  )
};

export { AuthProvider };
import { useState, useEffect } from 'react';

const useStateObserver = (defaultValue, onStateChange) => {
  const [state, setState] = useState(defaultValue);

  useEffect(() => {
    onStateChange();
  }, [state]);

  return [state, setState];
};

export { useStateObserver };
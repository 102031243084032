import { get } from 'lodash';
import { useAlgoliaSearch } from 'react-algolia';
import { getIndexNameFromType } from 'lib/algolia';

const constructFilter = ({ roles, status }) => {
  let filter = 'active:true';

  if (roles.length === 1 && roles.includes('admin')) {
    filter += ' AND role:organisation_admin';
  };

  if (status === 'active') {
    filter += ' AND organisation_license_consumed:true';
  };

  if (status === 'inactive') {
    filter += ' AND organisation_license_consumed:false';
  };

  return filter;
}

const useOrganisationUsersQuery = ({
  query,
  page = 1,
  pageSize = 10,
  filters = {
    roles: ['admin', 'user'],
    status: 'all'
  },
  sorter,
  key = 0
}) => {
  const { roles, status } = filters;
  const { searchResults, ...restProps } = useAlgoliaSearch({
    indexName: getIndexNameFromType('user', sorter),
    query,
    page: page - 1,
    hitsPerPage: pageSize,
    filters: constructFilter({ roles, status }),
    key
  });

  return {
    data: get(searchResults, 'hits') || [],
    pagination: {
      total: get(searchResults, 'nbHits') || 0,
      page,
      pageSize
    },
    ...restProps
  };
};

export { useOrganisationUsersQuery };
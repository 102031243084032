import gql from 'graphql-tag';

const PROFILE_QUERY = gql`
  query organisationQuery {
    userIdentity {
      id
      name
      email
      picture
      organisationLicenseConsumed
      managedTeamId
      createdAt
      pluralsightId
      pluralsightHandle
      originatesFrom
      roles {
        organisationAdmin
      }
    }
    organisation {
      id
      name
      website
      chargeModel
      accountType
      cohortMigrationDate
      createdAt
      subscription {
        startDate
        endDate
        expired
        userLicenseCount
        daysRemaining
      }
      deactivated
      consumedLicenses
      companyLogo
      customInvitationMessage
      features {
        allowInvitations
        trackLicenseReallocations
      }
      onboardingEvents
      widgetsVisibility {
        name
        visible
      }
      betaFeatureRequests {
        feature
        status
      }
      connectionName
      domains
      deactivatedLicenses
      joinCodes
      licenseReallocationStats {
        reallocationThreshold
        reallocationUsage
        reallocationUsagePercentage
      }
    }
    AccessPermissions_getUserPermissions {
      cloud {
        apiAccess
        certificationReporting
        customCloudSandboxes
        teamManagement
        customLearningPaths
        studyGroups
        handsOnLabs
        packageId
        packageName
      }
    }
    organisationOnboardingAccountSetupActions {
      id
      title
      description
      icon
      buttonText
      buttonAction
      buttonData
      showForPackage
      order
      completionEvent
      shouldCompleteEvent
      eventCompleted
    }
  }
`;

export { PROFILE_QUERY };

import { makeBuildOptimizedSrc } from 'components/image/ImageOptimizer';

const logoUrl = 'https://acloudguru-content-assets-production.s3-accelerate.amazonaws.com/1660798159214-ACG-logo_ACG-OrangeWhite.png';
const logoLightUrl = 'https://acloudguru-content-assets-production.s3-accelerate.amazonaws.com/1660884435947-ACG-logo_ACG-Blue.png';
const logoDesktopTransforms = makeBuildOptimizedSrc({ width: 200, format: 'auto' });
const logoMobileTransforms = makeBuildOptimizedSrc({ width: 150, format: 'auto' });

const imagePaths = {
  logoDesktop: logoDesktopTransforms(logoUrl),
  logoMobile: logoMobileTransforms(logoUrl),
  logoLightDesktop: logoDesktopTransforms(logoLightUrl),
  logoLightMobile: logoMobileTransforms(logoLightUrl),
};

export { imagePaths };

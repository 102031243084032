import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { get } from 'lodash';

const ORGANISATION_USER_COURSE_ACTIVITY_QUERY = (attributes) => gql`
  query organisationCourseActivityReport(
    $filters: OrganisationCourseActivityFilters,
    $pagination: Page
  ) {
    organisationCourseActivityReport (
      filters: $filters,
      pagination: $pagination
    ) ${attributes}
  }
`;

const useOrganisationUserCourseActivityQuery = ({
  page,
  pageSize,
  groupId,
  courseId,
  userIds,
  attributes,
  dateRange: {
    from,
    to
  } = {}
}) => {
  const { data, loading, error, refetch } = useQuery(
    ORGANISATION_USER_COURSE_ACTIVITY_QUERY(attributes), {
      variables: {
        filters: {
          groupId,
          userIds,
          courseId,
          dateRange: {
            from,
            to
          }
        },
        pagination: (page && pageSize) ? {
          pageNumber: page,
          pageSize
        } : undefined
      }
    }
  );

  return {
    data: get(data, 'organisationCourseActivityReport.data') || [],
    pagination: {
      page,
      pageSize,
      total: get(data, 'organisationCourseActivityReport.total') || 0
    },
    loading,
    error,
    refetch
  };
};

export {
  useOrganisationUserCourseActivityQuery
};

import gql from 'graphql-tag';

const REMOVE_USER_FROM_LEARNING_PATH = gql`
  mutation removeUserFromLearningPath($input: RemoveUserFromLearningPathInput!) {
    removeUserFromLearningPath(input: $input) {
      id
    }
  }
`;

export { REMOVE_USER_FROM_LEARNING_PATH };

import { useEffect } from 'react';
import { printHappyCloud } from 'utils/printConsole'

const PrintCloud = () => {
  useEffect(() => {
    printHappyCloud();

    return () => {
      if (console.clear) {
        console.clear();
      }
    }
  }, []);

  return null;
};

export { PrintCloud };
import gql from 'graphql-tag';

const ASSIGN_TEAM_TO_INVITATIONS_MUTATION = gql`
  mutation assignTeamToInvitations($input: AssignTeamToInvitationsInput!) {
    assignTeamToInvitations(input: $input) {
      taskId
    }
  }
`;

export { ASSIGN_TEAM_TO_INVITATIONS_MUTATION };

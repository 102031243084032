const ONBOARDING_EVENTS = {
  LOGO_UPLOADED: 'ORGANISATION_LOGO_UPLOADED',
  CUSTOM_MESSAGE_SET: 'ORGANISATION_INVITATION_CUSTOM_MESSAGE_SET',
  INVITATION_SENT: 'ORGANISATION_USER_INVITATION_SENT',
  STUDY_GROUP_CREATED: 'ORGANISATION_STUDY_GROUP_CREATED',
  REPORTING_GROUP_CREATED: 'ORGANISATION_REPORTING_GROUP_CREATED',
  LEARNING_PATH_ASSIGNED: 'ORGANISATION_LEARNING_PATH_ASSIGNED',
  TEAM_CREATED: 'ORGANISATION_TEAM_CREATED'
};

export { ONBOARDING_EVENTS };

/* eslint-disable no-useless-escape */
import { colours } from '@a-cloud-guru/rainbow-ui';

const HAPPY_CLOUD = `@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@&%*.........#%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@&#*.................*#@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@#........................../&@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@%/.............................../@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@/...................................%@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@%............................................*#%@@@@@@@@@@
@@@@@@@@@@@@@@@@&/.....................................................*%@@@@@@
@@@@@@@@@@@@@@@/.........................................................*/@@@@
@@@@@@@@@@@%#/..............................................................#@@
@@@@@@&#.....................................................................*@
@@@@@/......................................................................../
@@@/...........................................................................
@@*.....................,*/****//,..............*//***//*......................
&/.....................,/*......,/,............/*......,*/.....................
......................./*........*/...........,/........./,....................
...............................................................................
...............................................................................
....................................***********................................
@,...................................*////////.................................
@@....................................,*///**.................................@
@@@..........................................................................@@
@@@@.......................................................................@@@@
@@@@@@...................................................................@@@@@@`;

const HELLO_CLOUD_GURUS = `
 _          _ _              _                 _
| |__   ___| | | ___     ___| | ___  _   _  __| |   __ _ _   _ _ __ _   _ ___
| '_ \\ / _ \\ | |/ _ \\   / __| |/ _ \\\| | | |/ _\` |  / _\` | | | | '__| | | / __|
| | | |  __/ | | (_) | | (__| | (_) | |_| | (_| | | (_| | |_| | |  | |_| \\__ \\
|_| |_|\\___|_|_|\\___/   \\___|_|\\___/ \\__,_|\\__,_|  \\__, |\\__,_|_|   \\__,_|___/
                                                   |___/
`;
const printHappyCloud = () => {
  console.log(`%c${HAPPY_CLOUD}${HELLO_CLOUD_GURUS}`, `color: ${colours.orange}`);
};

export { printHappyCloud };

import { get, uniq } from 'lodash';
import { useProfile } from 'hooks/use-profile';

const useOnboarding = () => {
  const { organisation, updateOrganisation } = useProfile();
  const onboardingEvents = get(organisation, 'onboardingEvents') || [];
  const widgetsVisibility = get(organisation, 'widgetsVisibility') || [];

  const dismissWidget = (widgetName) => {
    const updatedWidgetVisibility = widgetsVisibility.map(({ name, visible, ...rest }) => {
      return {
        name,
        visible: widgetName === name ? false : visible,
        ...rest
      };
    });

    updateOrganisation({
      widgetsVisibility: updatedWidgetVisibility
    });
  };

  const completeOnboardingStep = (stepName) => {
    const updatedOnboardingEvents = uniq([...onboardingEvents, stepName]);
    updateOrganisation({
      onboardingEvents: updatedOnboardingEvents
    });
  };

  const isComplete = (stepName) => {
    return onboardingEvents.includes(stepName);
  };

  return {
    dismissWidget,
    completeOnboardingStep,
    isComplete
  };
};

export { useOnboarding };
import React from 'react';
import get from 'lodash/get';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { authService } from 'services/auth';
import { getConfig } from 'config/get-config';

const {
  BUGSNAG_API_KEY,
  BUGSNAG_RELEASE_STAGE,
  APP_VERSION
} = getConfig();

const REFRESH_TOKEN_ERROR = 'Unable to refresh token';
const IGNORED_ERRORS = new Set([
  'Network error: Failed to fetch',
  'Network request failed',
  'Not enough memory resources are available to complete this operation.', // Algolia IE11 error: https://stackoverflow.com/questions/49876081/ie11-not-enough-storage-is-available-to-complete-this-operation
  '<script> unable to load due to an `error` event on it', // Algolia error: browser is too old
  'Could not parse the incoming response as JSON, see err.more for details', // Algolia error: browser is too old
  'Failed to execute \'requestPictureInPicture\' on \'HTMLVideoElement\': Metadata for the video element are not loaded yet.'
]);

const bugsnagClient = BUGSNAG_API_KEY ? Bugsnag.createClient({
  apiKey: BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: BUGSNAG_RELEASE_STAGE,
  appVersion: APP_VERSION,
  onError: (event) => {
    if (typeof window === 'undefined') {
      return false;
    }

    const errMsg = event?.errors[0]?.errorMessage || '';

    if (IGNORED_ERRORS.has(errMsg) || errMsg.startsWith(REFRESH_TOKEN_ERROR)) {
      return false;
    }
  }
}) : null;

export const BugsnagErrorBoundary = bugsnagClient ? bugsnagClient.getPlugin('react').createErrorBoundary(React) : React.Fragment;

export const identifyCurrentUser = async () => {
  if (typeof window !== 'undefined' && bugsnagClient) {
    try {
      const user_id = await authService.getUserIdFromToken();
      bugsnagClient.user = {
        id: user_id
      };
    } catch (err) {
      //
    }
  }
}

export const send = async (error, opts) => {
  const isCancelled = (get(error, 'message') || error).includes('Network error: Failed to fetch');

  if (typeof window !== 'undefined' && bugsnagClient && !isCancelled) {
    identifyCurrentUser();
    return bugsnagClient.notify(error, { ...opts });
  }
};

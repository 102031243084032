import get from 'lodash.get';
import {
  useAlgoliaSearch
} from 'react-algolia';
import { getIndexNameFromType } from 'lib/algolia';

const currentDate = new Date().valueOf();

const FILTERS = {
  all: 'valid_until > 0',
  joined: 'accepted_at > 0',
  pending: `valid_until > ${currentDate} AND accepted_at = 0`,
  expired: `valid_until < ${currentDate} AND accepted_at = 0`
};

const useInvitationQuery = ({
  query,
  page = 1,
  pageSize = 10,
  status,
  key
}) => {
  const { searchResults, ...restProps } = useAlgoliaSearch({
    indexName: getIndexNameFromType('invitation'),
    query,
    page: page - 1,
    hitsPerPage: pageSize,
    filters: FILTERS[status],
    key
  });
  return {
    data: get(searchResults, 'hits') || [],
    pagination: {
      total: get(searchResults, 'nbHits') || 0,
      page,
      pageSize
    },
    ...restProps
  }
};

export {
  useInvitationQuery,
  FILTERS as INVITATION_FILTERS
};
import { useState, useEffect } from 'react';

const useInitialLoad = ({ loading }) => {
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (!loading && initialLoad) {
      setInitialLoad(false);
    }
  }, [loading]);

  return initialLoad;
};

export { useInitialLoad };
import { BillingNotifications } from 'page-modules/billing-notifications/BillingNotifications';
import { useSplit } from 'hooks/use-split';
import { useActiveSubscription } from 'lib/subscription/use-active-subscription';
import { useProfile } from 'hooks/use-profile';


const ShowBillingNotifications = () => {
  const {
    loading,
    profile: {
      organisationRoles: userRoles
    }
  } = useProfile();
  const isAdmin = !loading && userRoles.length && userRoles.includes('ORGANISATION_ADMIN');
  const { activeSubscription } = useActiveSubscription();
  const splitBillingNotifications = useSplit('BILLING_NOTIFICATIONS');
  const showBillingNotifications = isAdmin && activeSubscription && splitBillingNotifications;
  return showBillingNotifications ? <BillingNotifications /> : null;
}

export { ShowBillingNotifications };

import { getConfig } from 'config/get-config';
const { SEGMENT_API_KEY } = getConfig();

const useTrack = (defaultPayload = {}) => {
  return {
    track: SEGMENT_API_KEY ? (event, payload = {}) => window.analytics.track(event, {
      ...defaultPayload,
      ...payload
    }) : () => {}
  };
};

export { useTrack };
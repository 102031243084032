// This theme merely adds additional values to the default ACG 'rainbow-ui theme':
// https://github.com/ACloudGuru/a-cloud-guru/blob/master/packages/rainbow-ui/src/themes/default-theme.js

import { defaultTheme } from '@a-cloud-guru/rainbow-ui';

export const teamsTheme = {
  ...defaultTheme,

  // Space additions so we can continue to use meaningful spacing values.
  // "md" is our baseline, and maps to 16px

  // defaultTheme value maps @ rainbow-ui 3.4.1:
  // 's1': '4px'
  // 's2': '8px'
  // 's3': '12px'
  // 's4': '16px'
  // 's5': '20px'
  // 's6': '24px'
  // 's7': '28px'
  // 's8': '32px'
  // 's9': '36px'
  // 's10': '40px'
  // 's12': '48px'
  // 's14': '56px'
  // 's16': '64px'
  // 's18': '72px'
  // 's20': '80px'

  space: {
    ...defaultTheme.space,

    xs: defaultTheme.space.s1, // '4px'
    sm: defaultTheme.space.s2, // '8px'
    md: defaultTheme.space.s4, // '16px' - Use this as a baseline
    lg: defaultTheme.space.s5, // '20px'
    xl: defaultTheme.space.s8 // '32px
  }
};

import { lighten, rgba } from 'polished';
import styled from 'styled-components';
import { colour } from 'styles/variables';

const PageStatus = ({ header, children }) => (
  <Status>
    {header && <Title>{header}</Title>}
    {children}
  </Status>
);

const Status = styled.div`
  text-align: center;

  span,
  a {
    text-decoration: underline;
    cursor: pointer;
    color: ${rgba(0, 0, 0, 0.45)};

    &:hover {
      color: ${lighten(0.05, colour.gray900)};
    }
  }
`;

const Title = styled.h1`
  font-size: 24px;
  color: ${lighten(0.05, colour.gray900)};
`;

export { PageStatus };

import React from 'react';
import { useEffect, useContext } from 'react';
import { TrackingContext } from './TrackingContext';

const Children = ({ children }) => children;

const Memo = React.memo(Children);

const TrackingZone = ({
  context,
  children
}) => {
  const { setTrackingContext, clearContext } = useContext(TrackingContext);

  useEffect(() => {
    return () => {
      clearContext();
    }
  }, []);

  return (
    <div
      style={{ width: '100%' }}
      onMouseEnter={() => setTrackingContext(context)}
      onMouseLeave={() => setTrackingContext(context)}
    >
      <Memo>
        {children}
      </Memo>
    </div>
  );
};

export { TrackingZone };
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { get, sortBy } from 'lodash';

const TEAMS_QUERY = gql`
  query TEAMS_teams {
    teams {
      id
      name
      numberOfUsers
      numberOfCoordinators
    }
  }
`;

const useTeamsUserSummaryQuery = () => {
  const { data, loading, error, refetch } = useQuery(TEAMS_QUERY);

  const teams = get(data, 'teams') || [];

  return {
    data: sortBy(teams, 'name').map(({ id, name, numberOfUsers, numberOfCoordinators }) => ({
      id,
      name,
      total: numberOfUsers,
      numberOfUsers,
      numberOfCoordinators
    })),
    loading,
    error,
    refetch
  };
};

export { useTeamsUserSummaryQuery, TEAMS_QUERY };

import gql from 'graphql-tag';

const CUSTOM_LEARNING_PATH_PUBLISH = gql`
  mutation customLearningPathPublish($id: ID!) {
    customLearningPathPublish(id: $id) {
      id
      title
      description
      tileUrl
      updatedAt
      publishedAt
    }
  }
`;

export { CUSTOM_LEARNING_PATH_PUBLISH };

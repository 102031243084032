const {
  brandFont,
  colour,
  boxShadow,
  borderRadius
} = require('@a-cloud-guru/kermit');

const merge = require('lodash.merge');

const variables = merge({
    brandFont,
    colour,
    boxShadow,
    borderRadius
  },
  {
    colour: {
      yellow: '#fdc018',
      gray300: '#e2e7f3',
      gray500: '#78839e',
      brandNavy: '#261E7D',
      greenLight: '#02E188'
    }
  }
);

module.exports = variables;
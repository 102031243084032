import { useCallback } from 'react';
import { Icon, Modal, Box, colours, message } from '@a-cloud-guru/rainbow-ui';
import { useMutation } from '@apollo/react-hooks';
import { useRouter } from 'next/router';

import { useTrack } from './use-track';
import { DELETE_CUSTOM_LEARNING_PATH_MUTATION } from 'data/mutation';
import { ALL_LEARNING_PATHS_QUERY } from 'data/query-hooks';

const useDeletePathModal = ({ learningPathId, totalStudents = 0 }) => {
  const { track } = useTrack();
  const router = useRouter();

  const [deleteCustomLearningPathRequest] = useMutation(
    DELETE_CUSTOM_LEARNING_PATH_MUTATION,
    {
      variables: {
        id: learningPathId,
      },
      refetchQueries: [
        {
          query: ALL_LEARNING_PATHS_QUERY,
          variables: {
            includeCustomLearningPath: true
          },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  return useCallback(
    () => {
      const getTitle = () => (
        <Box>
          <strong>Are you sure you want to delete this path?</strong>
        </Box>
      );

      const getContent = () => {
        if (totalStudents > 0) {
          return (
            <Box>
              You have <strong>{totalStudents} {totalStudents === 1 ? 'person' : 'people'} assigned</strong>. Deleting it will mean they cannot continue on the path. All course progress will remain.
            </Box>
          );
        }
        return null;
      };

      return Modal.confirm({
        title: getTitle(),
        content: getContent(),
        okText: 'Delete path',
        okType: 'danger',
        cancelText: 'Cancel',
        icon: <Icon type="exclamation-circle-o" theme="filled" style={{ color: colours.red }} />,
        onOk() {
          return deleteCustomLearningPathRequest()
            .then(() => {
              track(`Path soft deleted`, { learningPathId });
              router.push('/learning-paths');
            })
            .catch((err) => {
              console.log(err);
              message.error('Failed to delete the path. Please try again.');
            });
        },
      });
    },
    [learningPathId, totalStudents, track, deleteCustomLearningPathRequest, router]
  );
};

export { useDeletePathModal };

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { get } from 'lodash';

const ORGANISATION_WAREHOUSE_SUMMARY_QUERY = ({
  queryName,
  response
}) => gql`
  query ${queryName}(
    $from: String,
    $to: String,
    $groupId: String,
    $courseId: String,
    $userIds: [String],
  ) {
    ${queryName}(
      filters: {
        dateRange: {
          from: $from,
          to: $to
        },
        groupId: $groupId,
        courseId: $courseId,
        userIds: $userIds
      }
    ) ${response}
  }
`;

const makeUseWarehouseSummaryQuery = ({
  queryName,
  response
}) => ({
  groupId,
  from,
  to,
  courseId,
  userIds
}) => {
  const { data, loading, error, refetch } = useQuery(
    ORGANISATION_WAREHOUSE_SUMMARY_QUERY({
      queryName,
      response
    }), {
      variables: {
        groupId,
        from,
        to,
        courseId,
        userIds
      }
    }
  );

  return {
    data: get(data, queryName) || [],
    loading,
    error,
    refetch
  };
};

export {
  makeUseWarehouseSummaryQuery,
  ORGANISATION_WAREHOUSE_SUMMARY_QUERY
};

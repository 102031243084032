import { useState, useEffect } from 'react';
import { get, keyBy } from 'lodash';
import { isBefore, subDays, subWeeks, format } from 'date-fns';
import { useAlgoliaIndex } from 'react-algolia';
import { getIndexNameFromType } from 'lib/algolia';
import { groupByTime, getFirstDays } from 'lib/datetime/group-by-time';

const NOW = new Date().valueOf();

const START_FROM = {
  day: subDays(NOW, 14).valueOf(),
  week: subWeeks(NOW, 12).valueOf()
};

const FILTERS = {
  all: 'valid_until > 0',
  joined: 'accepted_at > 0 AND valid_until > 0',
  pending: `valid_until > ${NOW} AND accepted_at = 0`,
  expired: `valid_until < ${NOW} AND accepted_at = 0 AND valid_until > 0`
};

const makeSortByDate = (sortKey) => (a, b) => isBefore(a[sortKey], b[sortKey]) ? -1 : 1;

const makeFillInMissingPeriods = ({
  interval,
  timestampKey,
  defaultValue,
}) => ({
  data,
  from,
  to
}) => {
  const firstDays = getFirstDays(
    data.map(d => new Date(d[timestampKey]).valueOf()),
    interval,
    new Date(from),
    true
  ).map(day => format(day, 'YYYY-MM-DD'));

  const dataOfFirstDays = data.map(d => d[timestampKey]);

  const dataMap = keyBy(data, timestampKey);

  return firstDays.map(day => {
    return dataOfFirstDays.includes(day)
      ? dataMap[day]
      : {
        ...defaultValue,
        [timestampKey]: day
      };
  });
}

const groupInvitations = ({
  invitations,
  timestampKey,
  interval
}) => {
  const sortByDate = makeSortByDate(timestampKey);
  const sortedInvitations = invitations.sort(sortByDate);

  const groupedData = groupByTime(sortedInvitations, timestampKey, interval);

  const periodData = Object.keys(groupedData).map(timestamp => {
    const startDate = new Date(parseInt(timestamp));
    const current = groupedData[timestamp];

    return {
      startDate: format(startDate, 'YYYY-MM-DD'),
      count: current.length
    }
  });

  const fillInMissingPeriods = makeFillInMissingPeriods({
    interval,
    timestampKey: 'startDate',
    defaultValue: {
      count: 0
    }
  });

  return fillInMissingPeriods({
    data: periodData,
    from: START_FROM[interval],
    to: NOW
  });
};

const merge = ({
  sentCountBeforeStart,
  acceptedCountBeforeStart,
  sentInvitationsByDate,
  acceptedInvitationsByDate
}) => {
  return sentInvitationsByDate.reduce((accu, sentInvitations, index) => {
    const { startDate } = sentInvitations;
    const sentCount = get(sentInvitations, 'count') || 0;
    const acceptedCount = get(acceptedInvitationsByDate, `${index}.count`) || 0;

    const current = {
      startDate: format(startDate, 'DD MMM'),
      sentCount: index === 0
        ? sentCountBeforeStart + sentCount
        : get(accu, `${index - 1}.sentCount`) + sentCount,
      acceptedCount: index === 0
        ? acceptedCountBeforeStart + acceptedCount
        : get(accu, `${index - 1}.acceptedCount`) + acceptedCount
    };

    return [
      ...accu,
      current
    ]
  }, []);
}

const useInvitationSummaryQuery = ({
  interval = 'week'
}) => {
  const [loading, setLoading] = useState(true);
  const [summary, setSummary] = useState({});
  const index = useAlgoliaIndex({
    indexName: getIndexNameFromType('invitation')
  });

  useEffect(() => {
    const getInvitationSummary = async () => {
      const [
        { nbHits: joinedCount },
        { nbHits: pendingCount },
        { nbHits: expiredCount },
        { nbHits: sentCountBeforeStart },
        { nbHits: acceptedCountBeforeStart },
        { hits: sentInvitations },
        { hits: acceptedInvitations }
      ] = await Promise.all([
        index.search({
          filters: FILTERS['joined'],
          hitsPerPage: 0
        }),
        index.search({
          filters: FILTERS['pending'],
          hitsPerPage: 0
        }),
        index.search({
          filters: FILTERS['expired'],
          hitsPerPage: 0
        }),
        index.search({
          filters: `(sent_at < ${START_FROM[interval]}) AND (valid_until > ${NOW}) OR accepted_at > 0`,
          hitsPerPage: 0
        }),
        index.search({
          filters: `accepted_at < ${START_FROM[interval]} AND accepted_at > 0 AND valid_until > 0`,
          hitsPerPage: 0
        }),
        index.search({
          filters: `sent_at > ${START_FROM[interval]} AND valid_until > ${NOW}`,
          hitsPerPage: 1000
        }),
        index.search({
          filters: `accepted_at > ${START_FROM[interval]} AND valid_until > 0`,
          hitsPerPage: 1000
        })
      ]);

      const sentInvitationsByDate = groupInvitations({
        invitations: sentInvitations,
        timestampKey: 'sent_at',
        interval,
      });

      const acceptedInvitationsByDate = groupInvitations({
        invitations: acceptedInvitations,
        timestampKey: 'accepted_at',
        interval
      });

      setSummary({
        ...summary,
        data: merge({
          sentCountBeforeStart,
          acceptedCountBeforeStart,
          sentInvitationsByDate,
          acceptedInvitationsByDate
        }),
        joinedCount,
        pendingCount,
        expiredCount
      });
      setLoading(false);
    }

    if (index) {
      getInvitationSummary();
    }
  }, [JSON.stringify(summary), index, interval]);

  return { summary, loading };
};

export { useInvitationSummaryQuery };
import { getConfig } from 'config/get-config';
import * as snippet from '@segment/snippet';
import qs from 'qs';

const { SEGMENT_API_KEY } = getConfig();

const parse = query => {
  return qs.parse(query, { ignoreQueryPrefix: true });
};

export const sendCurrentPage = () => {
  return sendPage(window.location.pathname, window.location.search);
};

export const sendPage = async (route, query) => {
  if (!process.browser || !window.analytics) { return; }
  window.analytics.page(route, { ...parse(query) });
};

const renderSnippet = apiKey => {
  const options = {
    apiKey,
    page: false
  };

  if (process.env.NODE_ENV === 'development') {
    return snippet.max(options);
  }

  return snippet.min(options);
};

export const Snippet = () => {
  return SEGMENT_API_KEY ? (
    <>
      <script dangerouslySetInnerHTML={{ __html: renderSnippet(SEGMENT_API_KEY) }} />
    </>
  ) : null;
};

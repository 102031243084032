const FILTER_ROLES = {
  STUDENT: 'STUDENT',
  ADMIN_STUDENT: 'ADMIN_STUDENT',
  ADMIN_ONLY: 'ADMIN_ONLY',
  TEAM_COORDINATOR_STUDENT: 'TEAM_COORDINATOR_STUDENT',
  TEAM_COORDINATOR_ONLY: 'TEAM_COORDINATOR_ONLY'
};

const ROLES = {
  STUDENT: 'STUDENT',
  ADMIN: 'ADMIN',
  TEAM_COORDINATOR: 'TEAM_COORDINATOR',
  ORGANISATION_ADMIN: "ORGANISATION_ADMIN"
};

const ROLES_DISPLAY_MAP = {
  TEAM_COORDINATOR: 'Team Coordinator',
  STUDENT: 'Student',
  ADMIN: 'Admin'
};

const FILTER_ROLES_DISPLAY_MAP = {
  STUDENT: 'Student',
  ADMIN_STUDENT: 'Admin + Student',
  ADMIN_ONLY: 'Admin',
  TEAM_COORDINATOR_ONLY: 'Team Coordinator',
  TEAM_COORDINATOR_STUDENT: 'Team Coordinator + Student'
};

export {
  ROLES,
  FILTER_ROLES,
  ROLES_DISPLAY_MAP,
  FILTER_ROLES_DISPLAY_MAP
};

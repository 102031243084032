import styled from 'styled-components';
import { colour, brandFont } from 'styles/variables';

const Subheader = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-family: ${brandFont};
  color: ${colour.gray400};
  margin: 0;
`;

export { Subheader };
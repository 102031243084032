import { useState } from 'react';
import { TrackingContext } from './TrackingContext';

const TrackingProvider = ({ children }) => {
  const [trackingContext, setTrackingContext] = useState({});
  const setTrackingContextValue = (updates) => {
    setTrackingContext({
      ...trackingContext,
      ...updates
    });
  };
  const clearContext =  () => {
    setTrackingContext({});
  };

  return (
    <TrackingContext.Provider value={{
      trackingContext,
      setTrackingContext: setTrackingContextValue,
      clearContext
    }}>
      {children}
    </TrackingContext.Provider>
  );
};

export { TrackingProvider };
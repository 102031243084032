import { get } from 'lodash';
import { useEffect } from 'react';
import { getConfig } from 'config/get-config';

const { SEGMENT_API_KEY } = getConfig();

const useIdentifyCurrentUser = ({
  organisation,
  profile
}) => {
  const organisationId = get(organisation, 'id');
  const userId = get(profile, 'id');

  useEffect(() => {
    const identifyCurrentUser = async ({ loggedInUserId }) => {
      if (!SEGMENT_API_KEY || !process.browser || !window.analytics || !loggedInUserId) {
        return;
      }

      window.analytics.identify(loggedInUserId);
    };

    if (organisationId && userId) {
      identifyCurrentUser({ loggedInUserId: get(profile, 'id'), });
    }
  }, [organisationId, userId]);
};

export { useIdentifyCurrentUser };

import get from 'lodash.get';
import { useAlgoliaSearch } from 'react-algolia';
import { getIndexNameFromType } from 'lib/algolia';

const getFilters = ({
  learningPathId,
  teamIds
}) => {
  let filters = 'active:true';

  if (learningPathId) {
    filters += ` AND learning_path:${learningPathId}`;
  }

  if (teamIds && !teamIds.includes('all')) {
    filters += ` AND ${teamIds.map(id => `team:${id}`).join(' OR ')}`;
  }

  return filters;
};

const useLearningPathUsersQuery = ({
  query,
  page = 1,
  pageSize = 10,
  learningPathId,
  teamIds,
  key = 0
}) => {
  const { searchResults, ...restProps } = useAlgoliaSearch({
    indexName: getIndexNameFromType('user'),
    query,
    page: page - 1,
    hitsPerPage: pageSize,
    filters: getFilters({ learningPathId, teamIds }),
    key
  });

  const hits = get(searchResults, 'hits') || [];

  const users = hits.map(hit => {
    // eslint-disable-next-line no-unused-vars
    const { objectID, team, name, picture, learning_path_level, ...rest } = hit;
    const learningPath = get(hit, `learning_path_${learningPathId}`);
    const startDate = get(learningPath, `start_date`) || null;
    const levelAchieved = get(learningPath, 'level') || 0;
    const levelAchievedTitle = get(learningPath, 'level_name') || 'No level attained';
    const completedPercent = get(learningPath, 'completedPercent') || 0.0;

    return {
      id: objectID,
      name,
      teamId: team === 'no-team' ? undefined : team,
      picture,
      startDate,
      levelAchieved,
      levelAchievedTitle,
      completedPercent,
      ...rest
    };
  });

  return {
    data: users,
    pagination: {
      total: get(searchResults, 'nbHits'),
      page,
      pageSize
    },
    ...restProps
  };
};

export { useLearningPathUsersQuery };

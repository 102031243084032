import gql from 'graphql-tag';

const DELETE_CUSTOM_LEARNING_PATH_MUTATION = gql`
  mutation customLearningPathDelete($id: ID!) {
    customLearningPathDelete(id: $id) {
      id
    }
  }
`;

export { DELETE_CUSTOM_LEARNING_PATH_MUTATION };

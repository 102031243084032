import { SplitIOProvider } from 'splitio-react';
import { get } from 'lodash';

import { useProfile } from 'hooks/use-profile';
import { useAuth } from 'hooks/use-auth';
import { getConfig } from 'config/get-config';

const { SPLIT_IO_API_KEY } = getConfig();

import { IDENTITY_FEATURE_SPLITS } from './feature-flags';

const SplitIO = ({
  children
}) => {
  const { userId, organisationId } = useAuth();
  const { organisation } = useProfile();

  const userLicenseCount = get(organisation, 'subscription.userLicenseCount') || 0;
  const licenseConsumedPercentage = userLicenseCount > 0 ? get(organisation, 'consumedLicenses') / userLicenseCount * 100 : 0;
  const betaFeatureRequests = get(organisation, 'betaFeatureRequests', []);
  const teamsFeatureRequest = betaFeatureRequests.find(
    featureRequest => get(featureRequest, 'feature') === 'ADMIN_PORTAL_TEAMS'
  );

  return (
    <SplitIOProvider
      splitKey={userId}
      authorizationKey={SPLIT_IO_API_KEY}
      attributes={{
        userId,
        organisationId,
        samlEnabled: !!get(organisation, 'connectionName'),
        autoJoinEnabled: get(organisation, 'domains.length', []) > 0,
        teamsFeatureStatus: get(teamsFeatureRequest, 'status'),
        chargeModel: get(organisation, 'chargeModel'),
        createdAt: new Date(get(organisation, 'createdAt', null)),
        licenseConsumedPercentage
      }}
      splits={[...IDENTITY_FEATURE_SPLITS]}
    >
      {children}
    </SplitIOProvider>
  )
};

export default SplitIO;

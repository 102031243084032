import { colours } from '@a-cloud-guru/rainbow-ui';
import styled from 'styled-components';
import { brandFont } from 'styles/variables';

import { Subheader } from './Subheader';

const Header = styled.h1`
  font-size: 30px;
  font-weight: 700;
  font-family: ${brandFont};
  color: ${colours.navy800};
  margin: 0;
`;

export { Header, Subheader };

import gql from 'graphql-tag';

const TOGGLE_WIDGET_VISIBILITY_MUTATION = gql`
  mutation toggleWidgetVisibility($widgetName: TeamWidget!, $visible: Boolean!) {
    toggleWidgetVisibility(input: {
      widgetName: $widgetName,
      visible: $visible
    }) {
      organisation {
        id
        widgetsVisibility {
          name
          visible
        }
      }
    }
  }
`;

export { TOGGLE_WIDGET_VISIBILITY_MUTATION };
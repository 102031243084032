import { useEffect, useState } from 'react';
import { useProfile } from 'hooks';

export const ClarityIdentifier = () => {
    const [data, setData] = useState(null);
    const { loading, profile, organisation, permissions } = useProfile();

    // Safety net for accessing a global variable
    const clarity = typeof window !== 'undefined' && window?.clarity;

    // Get required profile data 
    useEffect(
        () => {
            if (loading || !profile) { return; }

            // Set only if values are updated
            const { pluralsightId, id: userId, originatesFrom } = profile;
            const { id: organisationId } = organisation;
            const { packageId } = permissions;
            const newdata = { pluralsightId, userId, organisationId, packageId, originatesFrom };

            if (JSON.stringify(data) != JSON.stringify(newdata)) {
                setData(newdata);
            }
        },
        [loading, data, profile]
    );

    // Send additional clarity data
    useEffect(
        () => {
            if (!clarity || !data) { return; }

            clarity("identify", data?.pluralsightId);
            clarity("set", "acgUserId", data?.userId);
            clarity("set", "acgOrganisationId", data?.organisationId);
            clarity("set", "pluralsightId", data?.pluralsightId);
            clarity("set", "packageId", data?.packageId);
            clarity("set", "originatesFrom", data?.originatesFrom);
        },
        [clarity, data]
    );

    return null;
};

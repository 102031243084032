import { get, keyBy } from 'lodash';
import { isAfter } from 'date-fns';
import { useAlgoliaSearch } from 'react-algolia';
import { getIndexNameFromType } from 'lib/algolia';
import { usePrevious } from 'hooks';

import { getInvitationAccessFilter, transformInvitationsAccessRecords } from './invitation-access-query-helper';
import { getuserAssignmentsFilter, transformUsersAccessRecords } from './users-access-query-helper';

const mergeUsersAndInvitations = ({ users, invitations }) => {
  const transformedUsersRecords = transformUsersAccessRecords(users);
  const usersEmailMap = keyBy(transformedUsersRecords, 'email');
  const filterOutAcceptedInvitations = ({ email, status }) => !usersEmailMap[email] && status !== 'Joined';

  const transformedInvitationsRecords = transformInvitationsAccessRecords(invitations);

  return [...transformedUsersRecords, ...transformedInvitationsRecords.filter(filterOutAcceptedInvitations)].sort(
    (a, b) => (isAfter(a.last_activity, b.last_activity) ? -1 : 1)
  );
};

const useCombinedUserInvitationQuery = ({
  query = '',
  max = 200,
  filters = {
    roles: ['admin', 'user'],
    status: ['all'],
    teams: ['all']
  },
  transformFilters = filters => filters,
  sortOrder = 'descend',
  key = 0
} = {}) => {
  const transformedFilters = transformFilters(filters);
  const userFilter = getuserAssignmentsFilter(transformedFilters);
  const invitationFilter = getInvitationAccessFilter(transformedFilters);

  const {
    searchResults: userSearchResults,
    loading: userQueryLoading,
    error: userQueryError,
    index: userIndex
  } = useAlgoliaSearch({
    indexName: getIndexNameFromType('user', { field: 'last_seen_timestamp', order: sortOrder }),
    query,
    page: 0,
    hitsPerPage: max,
    filters: userFilter,
    key
  });
  const users = get(userSearchResults, 'hits') || [];
  const totalUsers = get(userSearchResults, 'nbHits') || 0;

  const {
    searchResults: invitationSearchResults,
    loading: invitationQueryLoading,
    error: invitationQueryError,
    index: invitationIndex
  } = useAlgoliaSearch({
    indexName: getIndexNameFromType('invitation', { field: 'sent_at', order: sortOrder }),
    query,
    page: 0,
    hitsPerPage: max,
    filters: invitationFilter,
    key
  });

  const invitations = get(invitationSearchResults, 'hits') || [];
  const totalInvitations = get(invitationSearchResults, 'nbHits') || 0;
  const data = mergeUsersAndInvitations({
    users,
    invitations
  }).slice(0, max);

  const prevData = usePrevious(data);

  const loading = userQueryLoading || invitationQueryLoading;
  const error = userQueryError || invitationQueryError;

  return {
    data: loading ? prevData : data,
    total: totalInvitations + totalUsers,
    totalUsers,
    loading,
    error,
    userIndex,
    invitationIndex
  };
};

export { mergeUsersAndInvitations, useCombinedUserInvitationQuery };

const LEARNING_PATH_DESCRIPTION_OVERWRITES = {
  'aws-developer': 'All things development including managed services, traditional monolithic stacks and serverless development',
  'aws-architect': 'Deep dive into the whole AWS ecosystem while covering foundational services',
  'aws-data': 'Covering all things data from databases and data lakes to tooling, migration and data best practices',
  'aws-devops': 'Reacting faster and taking advantage of automation and the power of Infrastructure as Code',
  'aws-executive': 'Understanding the challenges businesses will face in adopting the cloud',
  'aws-security': 'Focusing on secure protocols, data encryption, specialized data classifications and best practices'
};

const learningPathTypes = {
  ACG: 'ACG_LEARNING_PATH',
  CUSTOM: 'CUSTOM_LEARNING_PATH'
};

const learningPathTypeToDisplay = {
  [learningPathTypes.ACG]: 'ACG Career Path',
  [learningPathTypes.CUSTOM]: 'Custom Path'
};

const learningPathRoutes = {
  ACG: 'learning-path',
  CUSTOM: 'custom-learning-path',
};

export { LEARNING_PATH_DESCRIPTION_OVERWRITES, learningPathTypeToDisplay, learningPathTypes, learningPathRoutes };

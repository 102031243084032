const FEATURES = {
  API_ACCESS: 'apiAccess',
  CERTIFICATION_REPORTING: 'certificationReporting',
  CLOUD_SANDBOXES: 'customCloudSandboxes',
  HANDS_ON_LABS: 'handsOnLabs',
  TEAM_MANAGEMENT: 'teamManagement',
  STUDY_GROUPS: 'studyGroups',
  CUSTOM_LEARNING_PATHS: 'customLearningPaths'
};

const PACKAGE_NAME = {
  BUSINESS_BASIC: 'Business Basic',
  BUSINESS_PLUS: 'Business Plus'
};

const CLOUD_PLAYGROUND_TCS_CUTOFF_DATE = '2020-04-01T00:00:00.000Z';

const BULK_DEACTIVATION_ENABLED_ORGANISATION_IDS = [
  '345a7428-d017-0557-2081-5659cf6111e4' // Amazon
];

export {
  FEATURES,
  PACKAGE_NAME,
  CLOUD_PLAYGROUND_TCS_CUTOFF_DATE,
  BULK_DEACTIVATION_ENABLED_ORGANISATION_IDS
};
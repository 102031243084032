import gql from 'graphql-tag';

const MOVE_USER_TO_TEAMS_MUTATION = gql`
  mutation moveUsersToTeams ($input: MoveUsersToTeamsInput!) {
    moveUsersToTeams (input: $input) {
      taskId
    }
  }
`;

export { MOVE_USER_TO_TEAMS_MUTATION };
import { isUndefined } from 'lodash';
import { useSplitTreatment } from 'splitio-react';

const useSplit = (splitName) => {
  const treatment = useSplitTreatment(splitName);

  if (isUndefined(treatment)) {
    return;
  }

  return treatment === 'on';
};

export { useSplit };
import qs from 'qs';
import { get } from 'lodash';
import { useState } from 'react';
import Router from 'next/router';

const useUrlState = ({
  router,
  param,
  defaultValue,
  toUrl: toUrl = value => value,
  fromUrl: fromUrl = value => value
}) => {
  const { pathname, query = {} } = router;
  const queryValue = fromUrl(get(query, param));
  const [state, setState] = useState(queryValue || defaultValue);

  const setUrlState = (value) => {
    query[param] = toUrl(value);
    const href = `${pathname}?${qs.stringify(query)}`;
    const as = href;
    Router.push(href, as, { shallow: true });
    setState(value);
  };
  return [state, setUrlState];
};

export { useUrlState };

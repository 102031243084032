import gql from 'graphql-tag';

const CREATE_TEAM_MUTATION = gql`
  mutation TEAMS_createTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      team {
        id
        name
        deletedAt
      }
    }
  }
`;

export { CREATE_TEAM_MUTATION };

import { useMutation } from '@apollo/react-hooks';

import { CUSTOM_LEARNING_PATH_PUBLISH } from 'actions/group/mutations/Mutation.customLearningPathPublish';
import { ALL_LEARNING_PATHS_QUERY } from 'data/query-hooks';
import { useIsClpAuthorised } from 'hooks';

const usePublishPath = () => {
  const [publishPath] = useMutation(CUSTOM_LEARNING_PATH_PUBLISH);
  const { includeCustomLearningPath } = useIsClpAuthorised();

  return (id) =>
    publishPath({
      variables: { id },
      update: (proxy, { data: { customLearningPathPublish } }) => {
        // Read the data from our cache for this query.
        const data = proxy.readQuery({
          query: ALL_LEARNING_PATHS_QUERY,
          variables: { includeCustomLearningPath },
        });

        const allOrganisationLearningPaths = data.allOrganisationLearningPaths.map(
          (path) => {
            return path.id === id
              ? {
                ...path,
                publishedAt:
                  customLearningPathPublish?.publishedAt || new Date(),
              }
              : path;
          }
        );

        // Write our data back to the cache with the new comment in it
        proxy.writeQuery({
          query: ALL_LEARNING_PATHS_QUERY,
          variables: { includeCustomLearningPath },
          data: { allOrganisationLearningPaths },
        });
      },
    });
};

export { usePublishPath };

import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { LEARNING_PATH_DESCRIPTION_OVERWRITES } from 'constants/learning-path';
import { useIsClpAuthorised } from 'hooks';

const ALL_LEARNING_PATHS_QUERY = gql`
  query allOrganisationLearningPaths($includeCustomLearningPath: Boolean) {
    allOrganisationLearningPaths(includeCustomLearningPath: $includeCustomLearningPath) {
      id
      name
      tileUrl
      description
      shortDescription
      type
      publishedAt
      isReadOnly
    }
  }
`;

const mapResponseToLearningPaths = data => {
  return get(data, 'allOrganisationLearningPaths', []).map(({ id, shortDescription, ...learningPath }) => ({
    id,
    shortDescription: get(LEARNING_PATH_DESCRIPTION_OVERWRITES, id) || shortDescription,
    ...learningPath
  }));
};

const useLearningPathsQuery = () => {
  const { loading: loadingIsClpAuthorised, includeCustomLearningPath } = useIsClpAuthorised();
  const [fetch, { data, loading: loadingLearningPaths, error, refetch }] = useLazyQuery(ALL_LEARNING_PATHS_QUERY);

  useEffect(() => {
    if (loadingIsClpAuthorised) {
      // We are still resolving whether or not this user is part an org and if that org has CustomLearningPaths.
      return;
    }

    fetch({
      variables: {
        includeCustomLearningPath
      }
    });
  }, [fetch, loadingIsClpAuthorised, includeCustomLearningPath]);

  const loading = loadingLearningPaths || loadingIsClpAuthorised;

  return {
    data: data && mapResponseToLearningPaths(data),
    loading: !error && (loading || data === undefined),
    error,
    refetch
  };
};

export { useLearningPathsQuery, ALL_LEARNING_PATHS_QUERY };

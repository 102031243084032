import { useSplit } from 'hooks/use-split';
import { Box } from 'components/box';
import { PageStatus } from 'components/status/PageStatus';
import { SupportLink } from 'components/link/SupportLink';

const QueryError = ({ message, refetch = () => {} }) => {
  const on = useSplit('PLANNED_MAINTENANCE');
  if (on) {
    return (
      <Box p='20px'>
        <PageStatus header="Planned Maintenance">
          This feature is unavailable while we perform planned maintenance.
          We are currently cleaning out cobwebs in the cloud, please check back soon.
        </PageStatus>
      </Box>
    )
  }

  return (
    <Box p='20px'>
      <PageStatus header="An error occurred">
        {message || `Something has gone wrong.`}
        <br />
        Please either{' '}
        <span onClick={() => refetch()}>try again</span> or
        contact <SupportLink type="email" /> for help.
      </PageStatus>
    </Box>
  );
}

export { QueryError };

import { useState, useEffect } from 'react';
import * as Vibrant from 'node-vibrant';
import { camelCase } from 'lodash';

const getImagePalette = async (url) => {
  const response = await Vibrant.from(url).getPalette();
  const keys = Object.keys(response);
  const addPalette = (acc, paletteName) => ({
    ...acc,
    [camelCase(paletteName)]: response[paletteName] && response[paletteName].getHex()
  });
  const colourPalette = keys.reduce(addPalette, {});

  return colourPalette;
};

const usePalette = (image) => {
  const [palette, setPalette] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    if (!image) {
      return;
    }

    getImagePalette(image)
      .then(imagePalette => {
        setLoading(false);
        setPalette(imagePalette);
      })
      .catch(err => setError(err));
  }, [image]);

  return { palette, loading, error };
};

export { usePalette };

import { ApolloProvider } from 'react-apollo';
import SplitIO from 'lib/split-io';
import Algolia from 'lib/algolia';
import { withApolloClient } from 'lib/apollo/withApolloClient';
import { ProfileProvider } from 'providers/profile/ProfileProvider';
import { AuthProvider } from 'providers/auth/AuthProvider';
import { TrackingProvider } from 'providers/tracking';
import { PrintCloud } from 'page-components/PrintCloud';
import { IdentifyProvider } from 'providers/identify/IdentifyProvider'
import { ClarityIdentifier } from 'lib/clarity';

const TeamsPage = ({
  apolloClient,
  children
}) => {
  return (
    <AuthProvider>
      <PrintCloud />
      <TrackingProvider>
        <ApolloProvider client={apolloClient}>
          <ProfileProvider client={apolloClient}>
            <ClarityIdentifier />
            <SplitIO>
              <IdentifyProvider>
                <Algolia>
                  {children}
                </Algolia>
              </IdentifyProvider>
            </SplitIO>
          </ProfileProvider>
        </ApolloProvider>
      </TrackingProvider>
    </AuthProvider>
  )
}

const TeamsPageWithApolloClient = withApolloClient(TeamsPage);

export { TeamsPageWithApolloClient as TeamsPage };

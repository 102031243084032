import { colours } from '@a-cloud-guru/rainbow-ui';
import { createGlobalStyle } from 'styled-components';
import { rgba } from 'polished';

const GlobalStyle = createGlobalStyle`
  .learners-view-tooltip {
    .ant-tooltip-inner {
      background-color: ${colours.darkGrey900};
    }

    .ant-tooltip-arrow::before {
      background-color: ${colours.darkGrey900};
    }
  }

  .edit-path-tooltip {
    .ant-tooltip-inner {
      width: 253px;
      font-size: 12px;
      line-height: 21px;
      text-align: left;
      padding: 3px 6px;

      display: flex;
      flex-direction: row;
      align-items: flex-start;
      position: absolute;
      height: 48px;
      left: -16px;
      top: -28px;

      border-radius: 4px;
      background: ${colours.navy900};
      background-color: ${colours.navy900};
      box-shadow: 0px 4px 4px ${rgba(colours.navy900, 0.25)};
    }
  }

  .ant-btn-primary {
    min-width: 100px;

    :focus {
      box-shadow: 0 0 0 2px ${colours.lightGrey800};
    }
  }

  .ant-switch-checked {
    background-color: ${colours.blue};
  }

  .ant-tooltip-inner {
    background-color: ${rgba(colours.navy, 0.85)};
    text-align: center;
    padding: 12px 10px;
    font-size: 13px;
  }

  .ant-table-tbody > tr > td.ant-table-column-sort {
    background: #fff;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td.ant-table-column-sort {
    background: ${rgba(colours.blue100, 0.5)};
  }

  .ant-calendar-selected-day .ant-calendar-date,
  .ant-calendar-date:hover {
    background: ${colours.lightGrey900};
  }

  .ant-calendar-today .ant-calendar-date {
    border-color: ${colours.blue};
    color: ${colours.blue};
    background: ${colours.lightGrey900};
  }

  .ant-calendar-selected-date .ant-calendar-date {
    color: #fff;
  }

  .ant-calendar-selected-date .ant-calendar-date,
  .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
  .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
    background: ${colours.blue};

    :hover {
      background: ${colours.blue};
    }
  }

  .ant-calendar-header a:hover {
    color: ${colours.blue};
    background: ${colours.lightGrey900};
  }

  .ant-tooltip-inner a:hover {
    color: ${colours.blue};
  }

  .ant-table-thead > tr > th .ant-table-column-sorters:hover:before {
    background: #fff;
    content: unset;
  }

  .ant-select-dropdown-menu-item-active,
  .ant-select-dropdown-menu-item:hover {
    background: ${colours.lightGrey300};
  }

  .ant-table-tbody > tr.ant-table-row-selected td {
    background-color: ${rgba(colours.blue100, 0.5)};
  }

  .ant-modal-confirm-btns .ant-btn {
    background: ${colours.lightGrey300};
    border-color: ${colours.lightGrey300};

    :hover {
      background: ${colours.lightGrey500};
      border-color: ${colours.lightGrey500};
    }
  }

  .ant-modal-confirm-btns .ant-btn-primary {
    background: ${colours.blue};
    border-color: ${colours.blue};

    :hover {
      background: ${colours.blue};
      border-color: ${colours.blue};
    }
  }

  .ant-modal-confirm-btns .ant-btn-danger {
    color: #fff;
    background: ${colours.red};
    border-color: ${colours.red};

    :hover {
      background: ${colours.red};
      border-color: ${colours.red};
    }
  }

  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: ${colours.lightGrey300};
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #fff;
  }

  .ant-menu-vertical .ant-menu-item {
    margin: 0;
    color: ${colours.lightGrey900};
    background-color: #fff;

    :hover {
      background-color: ${colours.lightGrey300};
    }
  }

  .ant-menu-vertical .ant-menu-item:not(:first-child) {
    margin: 0;
    border-top: solid 1px ${colours.lightGrey300};
    border-radius: 0 0 5px 5px;
  }

  .ant-menu-vertical .ant-menu-item:first-child {
    border-radius: 5px 5px 0 0;
  }

  .ant-menu-vertical .ant-menu-item:not(:last-child) {
    margin-bottom: 0;
  }

  .ant-popover-inner-content {
    padding: 0px;
  }

  @keyframes fade-success {
    from {
      background-color: ${colours.green100};
    }
    to {
      background-color: inherit !important;
    }
  }

  @keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  .ant-table-tbody > tr.ant-table-row-success > td {
    animation-name: fade-success;
    animation-duration: 5s;
  }
`;

export { GlobalStyle };

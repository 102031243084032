import { get } from 'lodash';
import { usePrevious } from 'hooks/use-previous';
import { makeUseWarehouseSummaryQuery } from './use-warehouse-summary-query'

const useCompletedQuizExamSimSummaryQuery = ({
  groupId,
  from,
  to,
  courseId,
  userIds
}) => {
  const useCompletedExamSimSummaryQuery = makeUseWarehouseSummaryQuery({
    queryName: 'organisationExamSimCompletedSummaryReport',
    response: '{ completedExamSimCount }'
  });

  const useCompletedQuizSummaryQuery = makeUseWarehouseSummaryQuery({
    queryName: 'organisationQuizCompletedSummaryReport',
    response: '{ completedQuizCount }'
  });

  const {
    data: completedExamSimSummaryData,
    loading: completedExamSimSummaryLoading,
    error: completedExamSimSummaryError
  } = useCompletedExamSimSummaryQuery({
    groupId,
    from,
    to,
    courseId,
    userIds
  });

  const {
    data: completedQuizSummaryData,
    loading: completedQuizSummaryLoading,
    error: completedQuizSummaryError
  } = useCompletedQuizSummaryQuery({
    groupId,
    from,
    to,
    courseId,
    userIds
  });

  const currentData = {
    completedQuizCount: get(completedQuizSummaryData, 'completedQuizCount', 0),
    completedExamSimCount: get(completedExamSimSummaryData, 'completedExamSimCount', 0),
    combinedCompletedQuizExamSimCount: get(completedQuizSummaryData, 'completedQuizCount', 0) + get(completedExamSimSummaryData, 'completedExamSimCount', 0)
  };

  const prevData = usePrevious(currentData);

  const loading = completedExamSimSummaryLoading || completedQuizSummaryLoading;
  const error = completedExamSimSummaryError || completedQuizSummaryError;

  return {
    data: loading ? prevData : currentData,
    loading,
    error
  };
};

export {
  useCompletedQuizExamSimSummaryQuery
};

import get from 'lodash.get';
import { usePrevious } from 'hooks/use-previous';
import { makeUseWarehouseSummaryQuery } from './use-warehouse-summary-query'

const useCourseEngagementQuizActivityQuery = ({
  groupId,
  courseId,
  userIds,
  dateRange: {
    from,
    to
  } = {}
}) => {
  const useOrganisationQuizActivityQuery = makeUseWarehouseSummaryQuery({
    queryName: 'organisationQuizActivityReport',
    response: '{ data { id quizId fullName userId } }'
  });

  const useCourseEngagementActivityQuery = makeUseWarehouseSummaryQuery({
    queryName: 'organisationCourseEngagementActivityReport',
    response: '{ data { id componentId userId contentDuration } }'
  });

  const {
    data: completedQuizActivityData,
    loading: completedQuizActivityLoading,
    error: completedQuizActivityError
  } = useOrganisationQuizActivityQuery({
    groupId,
    from,
    to,
    courseId,
    userIds
  });

  const {
    data: courseEngagementActivityData,
    loading: courseEngagementActivityLoading,
    error: courseEngagementActivityError
  } = useCourseEngagementActivityQuery({
    groupId,
    from,
    to,
    courseId,
    userIds
  });

  const currentData = {
    completedQuizActivityData: get(completedQuizActivityData, 'data'),
    courseEngagementActivityData: get(courseEngagementActivityData, 'data')
  };

  const prevData = usePrevious(currentData);

  const loading = completedQuizActivityLoading || courseEngagementActivityLoading;
  const error = completedQuizActivityError || courseEngagementActivityError;

  return {
    data: loading ? prevData : currentData,
    loading,
    error
  };
};

export {
  useCourseEngagementQuizActivityQuery
};

import React from "react";
import { default as App } from 'next/app';
import Head from 'next/head';
import { getDataFromTree } from 'react-apollo';
import { isBrowser } from '../../utils/typedGlobals';
import { initApollo } from './initApollo';

const getDisplayName = Component => {
  return `withApolloClient ${Component.displayName ||
    Component.name ||
    'Unknown'}`;
};

const withApolloClient = NextApp => {
  return class Apollo extends React.Component {
    static displayName = getDisplayName(App);
    static async getInitialProps(appContext) {
      const { Component, router } = appContext;

      let appProps = {};
      if (NextApp.getInitialProps) {
        appProps = await NextApp.getInitialProps(appContext);
      }

      // Run all GraphQL queries in the component tree
      // and extract the resulting data
      const apollo = initApollo(
        {},
        {}
      );

      if (!isBrowser()) {
        try {
          // Run all GraphQL queries
          await getDataFromTree(
            <NextApp
              {...appProps}
              Component={Component}
              router={router}
              apolloClient={apollo}
            />
          );
        } catch (error) {
          // Prevent Apollo Client GraphQL errors from crashing SSR.
          // Handle them in components via the data.error prop:
          // http://dev.apollodata.com/react/api-queries.html#graphql-query-data-error
          console.error('Error while running `getDataFromTree`', error);
        }

        // getDataFromTree does not call componentWillUnmount
        // head side effect therefore need to be cleared manually
        Head.rewind();
      }

      // Extract query data from the Apollo store
      const apolloState = apollo.cache.extract();

      return {
        ...appProps,
        apolloState,
      };
    }

    constructor(props) {
      super(props);
      this.apolloClient = initApollo(props.apolloState, {});
    }

    render() {
      return <NextApp {...this.props} apolloClient={this.apolloClient} />;
    }
  };
};

export { withApolloClient };

import gql from 'graphql-tag';

const UPDATE_BILLING_NOTIFICATION_DISMISSED_AT_MUTATION = gql`
  mutation updateBillingNotificationDismissedAt($input: updateBillingNotificationDismissedAtInput!) {
    updateBillingNotificationDismissedAt(input: $input) {
      notification {
        id
        organisationId
        organisationIdActive
        message
        active
        createdAt
        dismissedAt
      }
    }
  }
`;

export { UPDATE_BILLING_NOTIFICATION_DISMISSED_AT_MUTATION };

import { notification, Button } from 'antd';
import { colours } from '@a-cloud-guru/rainbow-ui';
import Alert from '../../../public/images/svg-icons/alert.svg';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';
import { useState } from 'react';
import { UPDATE_BILLING_NOTIFICATION_DISMISSED_AT_MUTATION } from 'data/mutation';
import Router from 'next/router';

const notificationEventMessageMap = {
  'payment_failed': 'Oh no, your payment failed',
  'subscription_renewal_reminder': 'Subscription Renewal Reminder',
  'payment_source_expiring': 'Your Payment Source is Expiring',
  'payment_source_expired': 'Your Payment Source has Expired'
}

const BILLING_NOTIFICATIONS_QUERY = gql`
  query getBillingNotifications {
    billingNotifications {
      notifications {
        id
        organisationId
        organisationIdActive
        message
        active
        createdAt
        dismissedAt
        currentTermEnd
        eventType
      }
    }
  }
`;

const filterNotifications = ({ dismissedAt }) => {
  if (!dismissedAt) return true;
  const now = moment();
  const nextDisplayDate = moment(dismissedAt).add(1, 'day').hours(8).startOf('hour');
  return now.isSameOrAfter(nextDisplayDate);
};

const mapNotifications = (billingNotification) => ({
    ...billingNotification,
    message: billingNotification.message.replace(/\$currentTermEnd/g, moment(billingNotification.currentTermEnd).format('MMMM DD YYYY'))
});

const BillingNotifications = () => {

  const [dismissMutation] = useMutation(UPDATE_BILLING_NOTIFICATION_DISMISSED_AT_MUTATION, {
    onError: () => {
      message.error('Failed to remove billing notification. Please try again.')
    }
  })

  const handleDismissMutation = (notificationId) => {
    dismissMutation({
      variables: {
        input: {
          id: notificationId,
          dismissedAt: new Date().toISOString()
        }
      }
    });
  }

  const [skip, setSkip] = useState(false);
  const { loading: loadingBillingNotifications } = useQuery(BILLING_NOTIFICATIONS_QUERY, {
    onCompleted: (data) => {
      const notifications = data.billingNotifications.notifications;

      if (notifications && notifications.length && !skip) {
        setSkip(true);
        const btnStyle = {
          marginRight: '222px'
        };
        notifications
          .filter(filterNotifications)
          .map(mapNotifications)
          .forEach(({ message, id, eventType }) => {
            const key = id;
            const btn = (
              <button type="primary" size="small" style={btnStyle} onClick={() => notification.close(key)}>
                Update
              </button>
            );
            
            // need to use setTimeout otherwise notifications won't stack.
            setTimeout(() => {
              notification.open({
                key,
                btn,
                message: notificationEventMessageMap[eventType] || 'Alert',
                description: message,
                duration: 0,
                icon: <Alert />,
                style: {
                  background: colours.red50,
                  borderLeftWidth: '4px',
                  borderLeftStyle: 'solid',
                  borderLeftColor: colours.red700,
                  cursor: 'pointer',
                },
                onClose: () => {
                  handleDismissMutation(id);
                },
                onClick: () => {
                  Router.push('/billing');
                }
              })
            });
          })
      }
    }
  })
  return null;
};

export { BillingNotifications };

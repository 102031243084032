import { ProfileContext } from './ProfileContext';
import { useLoggedInUserProfile } from './use-logged-in-user-profile';

const ProfileProvider = ({ children, client }) => {
  const userProfile = useLoggedInUserProfile({ client });

  return (
    <ProfileContext.Provider value={{ profile: userProfile }}>
      {children}
    </ProfileContext.Provider>
  )
};

export { ProfileProvider };

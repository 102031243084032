import { ROLES, FILTER_ROLES } from 'constants';
import { flow, get } from 'lodash';
import { FILTER_ROLES_DISPLAY_MAP } from 'constants';

const CURRENT_TIME = new Date().valueOf();

const getRoleDescription = (invitation) => {
  if (invitation.invite_role === ROLES.ADMIN && !!invitation.organisation_admin_only) {
    return FILTER_ROLES_DISPLAY_MAP.ADMIN_ONLY;
  }

  if (invitation.invite_role === ROLES.ADMIN && !invitation.organisation_admin_only) {
    return FILTER_ROLES_DISPLAY_MAP.ADMIN_STUDENT;
  }

  if (invitation.invite_role === ROLES.TEAM_COORDINATOR && !!invitation.organisation_admin_only) {
    return FILTER_ROLES_DISPLAY_MAP.TEAM_COORDINATOR_ONLY;
  }

  if (invitation.invite_role === ROLES.TEAM_COORDINATOR && !invitation.organisation_admin_only) {
    return FILTER_ROLES_DISPLAY_MAP.TEAM_COORDINATOR_STUDENT;
  }

  return FILTER_ROLES_DISPLAY_MAP[invitation.invite_role];
};

const getInvitationStatus = ({ accepted_at, valid_until }) => {
  if (accepted_at) {
    return 'Joined';
  }

  if (CURRENT_TIME < valid_until) {
    return 'Pending';
  }

  return 'Expired';
};

const transformInvitationsAccessRecords = invitations => invitations
  .map(invitation => ({
    ...invitation,
    id: invitation.invitation_code,
    display: invitation.email,
    role_description: getRoleDescription(invitation) || 'Student',
    status: getInvitationStatus(invitation),
    last_activity: invitation.accepted_at || invitation.sent_at,
    team:
      get(invitation, 'team') === 'no-team'
        ? undefined
        : get(invitation, 'team'),
    type: 'invitation'
  }));

const getInvitationRoleFilter = ({ roles }) => (filter = 'accepted_at = 0') => {
  if (roles.includes('all')) {
    return filter;
  }

  filter += ` AND `;

  const filters = [];

  if (!roles.includes(FILTER_ROLES.ADMIN_STUDENT)) {
    filters.push(`NOT invite_role:${ROLES.ADMIN} OR NOT organisation_admin_only:false`);
  }

  if (!roles.includes(FILTER_ROLES.ADMIN_ONLY)) {
    filters.push(`NOT invite_role:${ROLES.ADMIN} OR NOT organisation_admin_only:true`);
  }

  if (!roles.includes(FILTER_ROLES.STUDENT)) {
    filters.push(`NOT invite_role:${ROLES.STUDENT}`);
  }

  if (!roles.includes(FILTER_ROLES.TEAM_COORDINATOR_ONLY)) {
    filters.push(`NOT invite_role:${ROLES.TEAM_COORDINATOR} OR NOT organisation_admin_only:true`);
  }

  if (!roles.includes(FILTER_ROLES.TEAM_COORDINATOR_STUDENT)) {
    filters.push(`NOT invite_role:${ROLES.TEAM_COORDINATOR} OR NOT organisation_admin_only:false`);
  }

  return filter + filters.join(' AND ');
};

const getInvitationStatusFilter = ({ status }) => (filter = 'accepted_at = 0') => {
  if (status.includes('pending') && !status.includes('expired')) {
    filter += ` AND valid_until > ${CURRENT_TIME}`;
  }

  if (!status.includes('pending') && status.includes('expired')) {
    filter += ` AND valid_until < ${CURRENT_TIME}`;
  }

  return filter;
};

const getInvitationTeamsFilter = ({ teams }) => (filter = 'accepted_at = 0') => {
  if (teams && !teams.includes('all')) {
    filter += ` AND ${teams.map(id => `team:${id}`).join(' OR ')}`;
  }

  return filter;
};

const getInvitationAccessFilter = ({
  roles,
  status,
  teams
}) => {
  const hasInvitationStatus = status.includes('all') || status.includes('pending') || status.includes('expired');

  if (!hasInvitationStatus) {
    return 'empty:true';
  }

  return flow(
    getInvitationRoleFilter({ roles }),
    getInvitationStatusFilter({ status }),
    getInvitationTeamsFilter({ teams })
  )('accepted_at = 0');
};

export { getInvitationAccessFilter, transformInvitationsAccessRecords };

import gql from 'graphql-tag';

const ASSIGN_ORGANISATION_ROLE_MUTATION = gql`
  mutation TEAMS_assignOrganisationUserRole ($input: AssignOrganisationUserRoleInput!) {
    assignOrganisationUserRole(input: $input) {
      taskId
    }
  }
`;

export { ASSIGN_ORGANISATION_ROLE_MUTATION };
import { Spinner } from '@a-cloud-guru/kermit';
import { colours } from '@a-cloud-guru/rainbow-ui';
import styled from 'styled-components';
import { space, width } from 'styled-system';

import { Footer } from './Footer';
import CloudWhistlingSvg from '../../../public/images/svg-icons/cloud-status/cloud-whistling.svg';
import { Box, Flex } from 'components/box';
import { Header } from 'components/header';
import { boxShadow } from 'styles/variables';
import { imagePaths } from 'utils/commonImageUrls';

const Masthead = () => (
  <MastheadContainer px="60px">
    <Flex justifyContent="space-between" height="64px" alignItems="center" width="100%">
      <Flex alignItems="center">
      <Logo src={imagePaths.logoDesktop} alt="" />
        <Flex ml="12px">
          {['120px', '86px', '110px', '64px', '82px'].map((width, i) => (
            <SkeletonBone width={width} type="darkSecondary" ml="12px" key={i} />
          ))}
        </Flex>
      </Flex>
      <Flex>
        {['38px', '120px'].map((width, i) => (
          <SkeletonBone width={width} type="darkSecondary" ml="12px" key={i} />
        ))}
      </Flex>
    </Flex>
    <Flex height="148px" pt="38px" flexDirection="column">
      <SkeletonBone width="168px" type="darkPrimary" />
      <SkeletonBone width="340px" type="darkSecondary" mt="14px" />
    </Flex>
  </MastheadContainer>
);

const ListItem = () => (
  <Flex py="16px" justifyContent="space-between">
    <Flex>
      <SkeletonAvatar />
      <Box ml="12px" mt="4px">
        <SkeletonBone width="168px" type="lightPrimary" />
        <SkeletonBone width="106px" type="lightPrimary" mt="10px" />
      </Box>
    </Flex>
    <SkeletonBone width="24px" type="lightPrimary" mt="4px" />
  </Flex>
);

const TeamsSkeletonLayout = ({ multiColumn = true }) => (
  <span>
    <Flex flexDirection="column" height="100vh">
      <Masthead />
      <Flex flex={1} ml="60px" mr="40px">
        <Body
          flex={2}
          p='80px'
          textAlign="center"
          mr="26px"
          maxWidth={multiColumn && "65%"}
        >
          <SingingCloud />
          <Box mt="24px">
            <Header>
              Hang tight, we&rsquo;re gathering clouds
            </Header>
          </Box>
          <Flex mt="38px" justifyContent="center">
            <Spinner size="3rem" />
          </Flex>
        </Body>
        {
          multiColumn && (
            <Body p="24px 20px" flex={1} height="fit-content">
              <SkeletonBone width="168px" mb="12px" />
              {[...Array(4)].map((_, i) => (
                <ListItem key={i} />
              ))}
            </Body>
          )
        }
      </Flex>
      <Footer />
    </Flex>
  </span>
);

const Logo = styled.img`
  position: relative;
  top: -2px;
  height: 28px;
`;

const SkeletonBone = styled.div`
  ${space};
  ${width};
  height: 10px;
  border-radius: 5px;
  background-color: ${({ type }) => {
    switch (type) {
      case 'darkPrimary':
        return colours.navy100;
      case 'darkSecondary':
        return colours.navy300;
      case 'lightPrimary':
        return colours.grey50;
      case 'lightSecondary':
      default:
        return colours.lightGrey300;
    }
  }};
`;

const SkeletonAvatar = styled.div`
  ${space};
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: ${colours.lightGrey300};
`;

const MastheadContainer = styled(Box)`
  background-color: ${colours.navy};
`;

const SingingCloud = styled(CloudWhistlingSvg)`
  position: relative;
  left: -30px;
`;

const Body = styled(Box)`
  background-color: #fff;
  position: relative;
  top: -40px;
  box-shadow: ${boxShadow.standard};
  max-height: 620px;
`;

export { TeamsSkeletonLayout };

import { useContext } from 'react';
import { getConfig } from 'config/get-config';
import { isObject } from 'lodash';
import { TrackingContext } from './TrackingContext';

const { SEGMENT_API_KEY } = getConfig();

const useTrack = () => {
  const { trackingContext } = useContext(TrackingContext);

  const track = (...args) => {
    if (!SEGMENT_API_KEY) {
      return;
    }

    const last = args.pop();
    const [props, ...events] = isObject(last)
      ? [{ ...last, page: window.location.pathname }, ...args]
      : [{ page: window.location.pathname }, ...args, last];

    window.analytics.track(['Teams', ...events].join(' > '), {
      ...props,
      ...trackingContext
    });
  };

  return track;
};

export { useTrack };
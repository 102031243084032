import groupBy from 'lodash.groupby';

const getMonday = (d) => {
  const day = d.getDay();
  const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
};

const getFirstDayOfMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1).valueOf();
};

const getFirstDayOfNextMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 1);
};

const groupByTime = (arr, key, group = 'week') => {
  const groupings = {
    day: (obj) => {
      const date = new Date(obj[key]);
      date.setHours(0, 0, 0, 0);
      return date.valueOf();
    },
    week: (obj) => {
      const date = new Date(obj[key]);
      date.setHours(0, 0, 0, 0);
      return getMonday(date).valueOf();
    },
    month: (obj) => {
      const date = new Date(obj[key]);
      return new Date(date.getFullYear(), date.getMonth(), 1).valueOf();
    }
  };

  return groupBy(arr, groupings[group]);
};

const getFirstDays = (dates, group, from, allowEmpty) => {
  const fromTimestamp = new Date(from).valueOf();
  const today = new Date().valueOf();

  const groups = {
    day: () => {
      const dayInMilliseconds = 24 * 60 * 60 * 1000;

      const firstDay = allowEmpty ? fromTimestamp : parseInt(dates[0] || fromTimestamp);
      const days = [];

      for (let day = firstDay; day <= today; day += dayInMilliseconds) {
        days.push(new Date(day).setHours(0, 0, 0, 0).valueOf());
      }
      return days;
    },
    week: () => {
      const weekInMilliseconds = 7 * 24 * 60 * 60 * 1000;

      const firstDayOfFrom = getMonday(from);
      const firstDay = parseInt(dates[0] || fromTimestamp);
      const days = [];

      for (let day = Math.min(firstDay, firstDayOfFrom); day <= today; day += weekInMilliseconds) {
        days.push(new Date(day).setHours(0, 0, 0, 0).valueOf());
      }
      return days;
    },
    month: () => {
      const firstDay = parseInt(dates[0] || fromTimestamp);
      const firstDayOfFrom = getFirstDayOfMonth(from);
      const days = [];

      for (let day = Math.min(firstDay, firstDayOfFrom); day <= today; day = getFirstDayOfNextMonth(new Date(day)).valueOf()) {
        days.push(new Date(day).setHours(0, 0, 0, 0).valueOf());
      }
      return days;
    }
  };

  return groups[group]();
};

export {
  getFirstDays,
  groupByTime
};

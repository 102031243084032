import { Link } from '.';

const SupportLink = ({ type, text = "support" }) => {
  if (type === "email") {
    return (
      <a href="mailto:support@acloud.guru">
        {text}
      </a>
    )
  };

  if (type === "form") {
    return (
      <a
        href="https://help.acloud.guru/hc/en-us/requests/new?"
        target="_blank"
        rel="noopener noreferrer"
      >
        {text}
      </a>
    )
  };

  return (
    <a
      href='https://help.acloud.guru'
      target="_blank"
      rel="noopener noreferrer"
    >
      { text }
    </a>
  );
};

export { SupportLink };

import { useEffect } from 'react';
import { useIsClpAuthorised } from 'hooks';

const useSyncPendo = ({ profile, teamCount }) => {
  const userId = profile && profile.id;
  const { loading, includeCustomLearningPath } = useIsClpAuthorised();

  useEffect(() => {
    const hasPendoIdentify = window && window.pendo && window.pendo.identify;

    if (!loading && userId && hasPendoIdentify) {
      window.pendo.identify({
        visitor: {
          id: userId,
          includeCustomLearningPath,
          teamCount
        }
      });
    }
  }, [loading, userId, includeCustomLearningPath, teamCount]);
};

export { useSyncPendo };

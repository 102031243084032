import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${props => props.borderSize || '2rem'};
  height: ${props => props.center ? '100vh' : 'inherit'};
`;

export { Container };

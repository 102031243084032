import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { get } from 'lodash';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import fetch from 'isomorphic-unfetch';
import { getConfig } from 'config/get-config';
import { authService } from 'services/auth';

import introspectionQueryResultData from './fragmentTypes.json';
import * as bugsnag from 'lib/bugsnag';
import { isBrowser } from 'utils/typedGlobals';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

let apolloClient;

// Polyfill fetch() on the server (used by apollo-client)
if (isBrowser()) {
  // add our own property to the global object
  // TODO: update this when isomorphicunfetch updates it's types
  global.fetch = fetch;
}

const authLink = () =>
  setContext(() => {
    if (!process.browser) {
      return;
    }
    return authService.getToken()
      .then(token => {
        return {
          headers: {
            authorization: token ? `Bearer ${token}` : `Bearer `,
          },
        };
      });
  });

const httpLink = () => {
  const { GRAPHQL_API_ENDPOINT } = getConfig();

  return new HttpLink({
    credentials: 'same-origin',
    uri: GRAPHQL_API_ENDPOINT,
  });
};

const errorLink = onError(({ networkError, graphQLErrors, operation, response }) => {
  const queryBody = get(operation, 'query.loc.source.body');
  const variables = get(operation, 'variables');
  const operationName = get(operation, 'operationName');

  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      bugsnag.send(
        new Error(
          `[GraphQL error]: ${JSON.stringify({
            message,
            locations,
            path,
            operationName,
            variables,
            queryBody
          }, ' ', 2)}`
        )
      )
    );
  }

  const isCancelled = (get(networkError, 'message') || '').includes('Failed to fetch');
  const isLoginRequired = (get(networkError, 'message') || '').includes('Unable to refresh token. Cause: Login required');

  if (networkError && !isCancelled && !isLoginRequired) {
    console.error('network error', networkError.message);
    bugsnag.send(new Error(`[Network error]: ${JSON.stringify({
      networkError,
      queryBody,
      variables,
      operationName,
      response
    }, ' ', 2)}`));
  }
});

const create = (
  initialState
) => {
  const state = { ...initialState };

  const {
    APP_VERSION,
    ACG_ENV
  } = getConfig();

  return new ApolloClient({
    connectToDevTools: isBrowser(),
    ssrMode: !isBrowser(), // Disables forceFetch on the server (so queries are only run once)
    link: ApolloLink.from([errorLink, authLink(), httpLink()]),
    cache: new InMemoryCache({ fragmentMatcher }).restore(state),
    name: `teams-${ACG_ENV}`,
    version: APP_VERSION
  });
};


const initApollo = (
  initialState,
  options
) => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!isBrowser()) {
    return create(initialState, options);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState, options);
  }

  return apolloClient;
};

export { initApollo };
